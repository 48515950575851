import modalTemplate from '../templates/modal';
import elementFromHtmlString from '../lib/element-from-html-string';

const ESC_KEY_CODE = 27;
const HEADING_SELECTOR = '.js-modal-heading';
const CLOSE_SELECTOR = '.js-modal-close';
const HASH = 'modal';
const LOADING_STATE = 'is-loading';
const IS_PADDED_STATE = 'is-padded';
const DEFAULT_OPTIONS = {
  addHideBindings: true,
  onHide: () => {},
};

class Modal {
  constructor(content = '', options = {}) {
    this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    this.element = this.template(content, this.options.template);
    this.component = this.element.querySelector('.Modal');
    this.content = this.element.querySelector('.Modal-content');
    this.main = this.element.querySelector('.Modal-main');
    this.pageWrapper = document.querySelector('.js-page-wrapper');
    this.closeButtons = [];
    this.pageYOffsetBeforeOpen = 0;
    this.allowClickOutside = true;

    this.hide = this.hide.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onHashChange = this.onHashChange.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onBodyClick = this.onBodyClick.bind(this);

    this.hideVisually();

    document.body.appendChild(this.element);
  }

  addHideBindings() {
    this.closeButtons = Array.from(document.querySelectorAll(CLOSE_SELECTOR));

    this.closeButtons.forEach((el) => {
      el.addEventListener('click', this.hide);
    });

    if (this.options.addHideBindings) {
      window.addEventListener('keydown', this.onKeyDown);
      window.addEventListener('hashchange', this.onHashChange);
      this.content.addEventListener('mousedown', this.onMouseDown);
      this.content.addEventListener('mouseup', this.onMouseUp);
      document.body.addEventListener('click', this.onBodyClick);
    }
  }

  removeHideBindings() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('hashchange', this.onHashChange);
    this.content.removeEventListener('mousedown', this.onMouseDown);
    this.content.removeEventListener('mouseup', this.onMouseUp);
    document.body.removeEventListener('click', this.onBodyClick);

    this.closeButtons.forEach((el) => {
      el.removeEventListener('click', this.hide);
    });
  }

  template(content, options) {
    return elementFromHtmlString(modalTemplate(content, options));
  }

  onKeyDown(event) {
    if (event.keyCode === ESC_KEY_CODE) {
      this.hide();
    }
  }

  onHashChange(event) {
    // IE11 does not have the `oldURL` property and will not get back button support
    const { oldURL } = event;

    if (oldURL && oldURL.includes(`#${HASH}`)) {
      this.hide();
    }
  }

  onMouseDown() {
    this.allowClickOutside = false;
  }

  onMouseUp() {
    this.allowClickOutside = true;
  }

  onBodyClick(event) {
    if (this.allowClickOutside && this.isClickOutside(event.target)) {
      this.hide();
    }

    this.allowClickOutside = true;
  }

  isClickOutside(target) {
    return target.parentNode === this.element;
  }

  showVisually() {
    this.pageWrapper.setAttribute('hidden', '');
    this.element.removeAttribute('hidden');
  }

  hideVisually() {
    this.pageWrapper.removeAttribute('hidden');
    this.element.setAttribute('hidden', '');
  }

  // "Public" methods
  show() {
    this.pageYOffsetBeforeOpen = window.pageYOffset;

    window.location.hash = HASH;

    this.addHideBindings();
    this.showVisually();

    window.scrollTo(0, 0);
  }

  hide() {
    this.hideVisually();
    this.removeHideBindings();

    window.location.hash = '';

    window.scrollTo(0, this.pageYOffsetBeforeOpen);

    this.options.onHide();
  }

  remove() {
    this.removeHideBindings();

    document.body.removeChild(this.element);
  }

  setLoadingState() {
    this.component.classList.add(LOADING_STATE);
  }

  unsetLoadingState() {
    this.component.classList.remove(LOADING_STATE);
  }

  updateHeading(content) {
    this.element.querySelector(HEADING_SELECTOR).innerHTML = content;
  }

  updateMainContent(content) {
    this.main.innerHTML = content;
  }

  executeScripts() {
    // Script tags need to be manually recreated in order to get executed
    Array.from(this.element.querySelectorAll('script')).forEach((script) => {
      const src = script.getAttribute('src');
      const clone = document.createElement('script');

      if (src) {
        clone.setAttribute('src', src);
      } else {
        clone.textContent = script.innerText;
      }

      script.parentNode.replaceChild(clone, script);
    });
  }

  togglePadded(padded) {
    if (padded === true) {
      this.main.classList.add(IS_PADDED_STATE);
    } else if (padded === false) {
      this.main.classList.remove(IS_PADDED_STATE);
    }
  }
}

export default Modal;
