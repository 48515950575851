import UASniffer from './ua-sniffer';

function openBankIdApp(autoStartToken, returnUrlForiOS) {
  const queryParams = [];
  let returnUrl = 'null'; // Intentionally a string

  if (autoStartToken) {
    queryParams.push(`autoStartToken=${autoStartToken}`);
  }

  if (returnUrlForiOS && UASniffer.iOSSafari()) {
    // "#" is there to prevent browser from reloading the page when returning
    returnUrl = window.encodeURIComponent(`${window.location.href}#`);
  }

  queryParams.push(`redirect=${returnUrl}`);

  // Intentionally triple ///
  window.location.replace(`bankid:///?${queryParams.join('&')}`);
}

export default openBankIdApp;
