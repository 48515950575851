import { Controller } from 'stimulus';

const CURRENT_CLASS_NAME = 'is-current';
const HIDDEN_CLASS_NAME = 'is-hidden';

class TabsController extends Controller {
  static targets = ['tab', 'panel']

  connect() {
    this.onHashChange = this.onHashChange.bind(this);

    this.removePanelIds(); // Prevent jump when clicking a tab
    this.setupEventListeners();
    this.openTabFromLocationHash();
  }

  onHashChange() {
    this.openTabFromLocationHash();
  }

  removePanelIds() {
    this.panelTargets.forEach((panel) => {
      panel.removeAttribute('id');
    });
  }

  setupEventListeners() {
    window.addEventListener('hashchange', this.onHashChange);
  }

  openTabFromLocationHash() {
    const hash = window.location.hash.replace(/^#/, '');
    let tab;

    if (hash) {
      tab = this.tabTargets.find(el => el.querySelector('a').href.split('#')[1] === hash);
    } else {
      [tab] = this.tabTargets;
    }

    if (tab && !tab.classList.contains(CURRENT_CLASS_NAME)) {
      this.openTab(tab);
    }
  }

  resetTabs() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(CURRENT_CLASS_NAME);
    });
  }

  setCurrentTab(item) {
    item.classList.add(CURRENT_CLASS_NAME);
  }

  resetPanels() {
    this.panelTargets.forEach((panel) => {
      panel.classList.add(HIDDEN_CLASS_NAME);
    });
  }

  setCurrentPanel(panel) {
    panel.classList.remove(HIDDEN_CLASS_NAME);
  }

  openTab(tab) {
    const panelIndex = this.tabTargets.indexOf(tab);
    const panel = this.panelTargets[panelIndex];

    this.resetTabs();
    this.setCurrentTab(tab);
    this.resetPanels();
    this.setCurrentPanel(panel);
  }
}

export {
  TabsController,
  TabsController as default,
};
