import isValidLuhn from './is-valid-luhn';

// Expects date string with either a short year "yymmdd" or a full year "yyyymmdd"
function extractDateParts(value) {
  const offset = value.length > 6 ? 2 : 0;
  let [year, month, day] = [ // eslint-disable-line prefer-const
    value.slice(0, 2 + offset),
    value.slice(2 + offset, 4 + offset),
    value.slice(4 + offset, 6 + offset),
  ].map(n => parseInt(n, 10));

  // Handle ”coordination numbers” https://en.wikipedia.org/wiki/National_identification_number#Sweden
  if (day >= 61 && day <= 91) {
    day -= 60;
  }

  return [year, month, day];
}

function hasValidDate(value) {
  const [year, month, day] = extractDateParts(value);
  const yearInputLength = Math.ceil(Math.log10(year + 1));
  const today = new Date();
  const date = new Date(year, month - 1, day);

  return (
    year === (yearInputLength === 4 ? date.getFullYear() : date.getYear())
    && month === date.getMonth() + 1
    && day === date.getDate()
    && date <= today
  );
}

function isValidPersonalIdentityNumber(value) {
  const pinValue = (value.length === 12) ? value.slice(2, 12) : value;
  const dateValue = value.slice(0, -4);

  return (
    pinValue.length === 10
    && isValidLuhn(pinValue)
    && hasValidDate(dateValue)
  );
}

export default isValidPersonalIdentityNumber;
