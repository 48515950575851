const CHARCODE_0 = '0'.charCodeAt(0);
const LUHN_MAPPING_EVEN = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

function luhnRemainder(value) {
  let [length, acc, bit] = [value.length, 0, 0];

  while (length > 0) {
    length -= 1;
    bit ^= 1; // eslint-disable-line no-bitwise

    if (bit) {
      acc += value.charCodeAt(length) - CHARCODE_0;
    } else {
      acc += LUHN_MAPPING_EVEN[value.charCodeAt(length) - CHARCODE_0];
    }
  }

  return acc % 10;
}

function isValidLuhn(value) {
  return luhnRemainder(value) === 0;
}

export default isValidLuhn;
