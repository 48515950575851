import { Controller } from 'stimulus';

import INPUT_EVENT_NAME from '../lib/input-event-name';

const DEFAULT_INPUT_EVENT_NAME = 'range-slider-change';

class RangeSliderController extends Controller {
  static targets = ['input']

  connect() {
    const controllerEventName = this.element.getAttribute('data-range-slider-on-change-event-name');
    const eventName = controllerEventName || DEFAULT_INPUT_EVENT_NAME;
    const customValuesData = this.element.getAttribute('data-range-slider-custom-values');
    const customValues = customValuesData ? customValuesData.split(',') : null;

    this.inputTarget.addEventListener(INPUT_EVENT_NAME, (event) => {
      const value = parseInt(event.target.value, 10);
      let customValuesDetail = null;
      let customValue = null;

      if (customValues) {
        customValue = customValues[value].split(':');
        customValuesDetail = {
          value: parseInt(customValue[0], 10),
          valueOutput: parseInt(customValue[1], 10),
        };
      }

      const changeEvent = new CustomEvent(eventName, {
        detail: {
          value,
          customValues: customValuesDetail,
        },
      });

      window.dispatchEvent(changeEvent);
    });
  }
}

export {
  RangeSliderController,
  RangeSliderController as default,
};
