import { Controller } from 'stimulus';

import { EventBuffer } from '../lib/event-buffer';
import castAsBoolean from '../lib/cast-as-boolean';
import fetchWithDefaults from '../lib/fetch-with-defaults';

import Modal from '../modules/modal';

const DEFAULT_CONTENT_SELECTOR = 'main';
const DEFAULT_SUFFIX = '.minimal';
const HTML_TYPE = 'html';
const IMAGE_TYPE = 'image';
const LOADED_EVENT = 'load-in-modal-content-loaded';

class LoadInModalController extends Controller {
  initialize() {
    this.onLoadedEvent = this.onLoadedEvent.bind(this);
  }

  connect() {
    this.modal = null;
  }

  openAction(e) {
    e.preventDefault();

    if (!this.modal) {
      const template = this.template();

      this.modal = new Modal(null, {
        template,
      });
    }

    this.modal.setLoadingState();
    this.modal.show();

    switch (this.type) {
      case IMAGE_TYPE:
        this.fetchImage();
        break;
      case HTML_TYPE:
      default:
        this.fetchHTML();
        break;
    }
  }

  fetchImage() {
    const image = document.createElement('img');

    image.addEventListener('load', this.onResponseImage.bind(this, image));
    image.addEventListener('error', this.onFetchError.bind(this));

    image.setAttribute('src', this.url);
  }

  fetchHTML() {
    fetchWithDefaults(this.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} (${response.statusText})`);
        }

        return response.text();
      })
      .then(this.onResponseHTML.bind(this))
      .catch(this.onFetchError.bind(this));
  }

  onResponseImage(image) {
    this.modal.updateMainContent = image;

    this.modal.unsetLoadingState();
  }

  onResponseHTML(html) {
    const tempElement = document.createElement('div');

    tempElement.innerHTML = html;

    const contentElement = tempElement.querySelector(this.contentSelector);

    if (!contentElement) {
      throw new Error(`Element not found: ${this.contentSelector}`);
    }

    this.modal.updateMainContent(contentElement.outerHTML);
    this.modal.executeScripts();

    if (this.useExplicitLoadedEvent) {
      EventBuffer.on(LOADED_EVENT, this.onLoadedEvent);
    } else {
      this.modal.unsetLoadingState();
    }
  }

  onFetchError(err) {
    this.modal.hide();
    this.modal.unsetLoadingState();

    console.error(err); // eslint-disable-line no-console
  }

  onLoadedEvent() {
    this.modal.unsetLoadingState();

    EventBuffer.off(LOADED_EVENT, this.onLoadedEvent);
  }

  template() {
    const template = {};

    if (this.closable === false) { // Explicit check for false
      template.header = null;
    }

    if (this.dismissableButtonText || this.checkboxToggleButtonText) {
      template.footer = {
        dismissableId: this.dismissableId,
        checkboxToggleId: this.checkboxToggleId,
        accept: this.dismissableButtonText || this.checkboxToggleButtonText,
        cancel: this.cancelable ? 'avbryt' : null,
      };
    }

    return template;
  }

  get type() {
    return this.data.get('type') || HTML_TYPE;
  }

  get addSuffix() {
    if (this.type !== HTML_TYPE) {
      return false;
    }

    const addSuffixDataAttribute = this.data.get('add-suffix');

    if (typeof addSuffixDataAttribute === 'string' && castAsBoolean(addSuffixDataAttribute) !== false) {
      return addSuffixDataAttribute;
    }

    // Default to true if no value is set
    if (addSuffixDataAttribute === null) {
      return true;
    }

    return false;
  }

  get url() {
    const href = this.data.get('href') || this.element.getAttribute('href');
    const [path, search] = href.split('?');

    const addSuffix = this.addSuffix; // eslint-disable-line prefer-destructuring
    const suffix = typeof addSuffix === 'string' ? addSuffix : DEFAULT_SUFFIX;

    if (addSuffix) {
      if (href.substr(-suffix.length) !== suffix) {
        return `${path}${suffix}${search ? `?${search}` : ''}`;
      }
    }

    return href;
  }

  get closable() {
    const closable = this.data.get('closable') || true;

    return castAsBoolean(closable);
  }

  get dismissable() {
    return !!(castAsBoolean(this.data.get('dismissable')) || this.dismissableId || this.dismissableButtonText);
  }

  get checkboxToggle() {
    return !!(castAsBoolean(this.data.get('checkbox-toggle')) || this.checkboxToggleId);
  }

  get cancelable() {
    const cancelable = this.data.get('cancelable') || true;

    return (this.dismissable || this.checkboxToggle) && castAsBoolean(cancelable);
  }

  get dismissableId() {
    return this.data.get('dismissable-id');
  }

  get dismissableButtonText() {
    return this.data.get('dismissable-button-text');
  }

  get checkboxToggleId() {
    return this.data.get('checkbox-toggle-id');
  }

  get checkboxToggleButtonText() {
    return this.data.get('checkbox-toggle-button-text');
  }

  get contentSelector() {
    return this.data.get('content-selector') || DEFAULT_CONTENT_SELECTOR;
  }

  get useExplicitLoadedEvent() {
    const useExplicitLoadedEvent = this.data.get('explicit-loaded-event') || false;

    return castAsBoolean(useExplicitLoadedEvent);
  }
}

export {
  LoadInModalController,
  LoadInModalController as default,
};
