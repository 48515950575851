import { Controller } from 'stimulus';

const DEFAULT_POPUP_WIDTH = 560;
const DEFAULT_POPUP_HEIGHT = 500;

class PopupController extends Controller {
  initialize() {
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  connect() {
    this.width = parseInt(this.data.get('width'), 10) || DEFAULT_POPUP_WIDTH;
    this.height = parseInt(this.data.get('height'), 10) || DEFAULT_POPUP_HEIGHT;

    this.element.addEventListener('click', this.onLinkClick, false);
  }

  onLinkClick(event) {
    const left = Math.round((window.innerWidth / 2) - (this.width / 2));
    const top = Math.round((window.innerHeight / 2) - (this.height / 2));
    const options = [
      `width=${this.width}`,
      `height=${this.height}`,
      'resizable=yes',
      `top=${top}`,
      `left=${left}`,
    ].join(',');

    event.preventDefault();

    window.open(this.element.href, '', options);
  }
}

export {
  PopupController,
  PopupController as default,
};
