/*
 * Toggle Content Controller
 */

import { Controller } from 'stimulus';

import { EventBuffer } from '../lib/event-buffer';

import toggleContentReducer from '../reducers/toggle-content-reducer';

import {
  ACTIVE_CLASS_NAME,
  EVENT_NAME,
  HIDE_MAIN_CONTENT_BEHAVIOR,
  DISABLE_FORM_ELEMENTS_BEHAVIOR,
  MAIN_CONTENT_SELECTOR,
  PARENT_ACTIVE_CLASS_NAME,
  PARENT_TARGET_ACTIVE_CLASS_NAME_BASE,
  PARENT_TARGET_ACTIVE_CLASS_NAME_REGEXP,
  FOCUS_SELECTOR,
  TOGGLE_TARGET_ACTION,
} from '../constants/toggle-content';

const FORM_ELEMENTS_DISABLED_CLASS_NAME = 'js-toggle-content-form-elements-disabled';
const CLICK_EVENT = 'click';
const CHANGE_EVENT = 'change';

function getPrefixedDatasetAttribute(el, attr) {
  return el.dataset[`toggleContent${attr.slice(0, 1).toUpperCase()}${attr.slice(1)}`];
}

class ToggleContentController extends Controller {
  static targets = ['trigger', 'target'];

  state = toggleContentReducer();

  initialize() {
    this.onTriggerDelegateAction = this.onTriggerDelegateAction.bind(this);
  }

  connect() {
    this.activeClassName = this.data.get('activeClassName') || ACTIVE_CLASS_NAME;
    this.inactiveClassName = this.data.get('inactiveClassName');

    this.mainContentElement = document.querySelector(MAIN_CONTENT_SELECTOR);

    this.element.addEventListener(CLICK_EVENT, this.onTriggerDelegateAction, true);
    this.element.addEventListener(CHANGE_EVENT, this.onTriggerDelegateAction, true);

    this.triggerTargets.forEach((el) => {
      if (el.checked) {
        this.triggerToggle(el);
      }
    });
  }

  disconnect() {
    this.element.removeEventListener(CLICK_EVENT, this.onTriggerDelegateAction, true);
    this.element.removeEventListener(CHANGE_EVENT, this.onTriggerDelegateAction, true);
  }

  triggerToggle(el) {
    const target = getPrefixedDatasetAttribute(el, 'target');
    const behavior = getPrefixedDatasetAttribute(el, 'behavior');

    this.dispatch({
      type: TOGGLE_TARGET_ACTION,
      payload: {
        target,
        behavior,
      },
    });
  }

  onTriggerDelegateAction(e) {
    const currentTarget = e.target.closest(`[data-target~="${this.identifier}.trigger"]`);

    if (!currentTarget) {
      return;
    }

    switch (e.type) {
      case CHANGE_EVENT:
        this.triggerToggle(currentTarget);
        break;
      case CLICK_EVENT:
      default:
        if (!('checked' in currentTarget)) {
          e.preventDefault();
          this.triggerToggle(currentTarget);
        }
    }
  }

  dispatch(action) {
    const previousState = this.state;
    const newState = toggleContentReducer(previousState, action);

    this.state = newState;

    if (JSON.stringify(previousState) !== JSON.stringify(newState)) {
      this.render(previousState);
    }
  }

  render(previousState) {
    const {
      target,
      behavior,
    } = this.state;

    const {
      target: previousTarget,
    } = previousState;

    if (target !== previousTarget) {
      EventBuffer.trigger(EVENT_NAME, {
        target: target || previousTarget,
        active: !!target,
        behavior,
      });
    }

    this.updateTriggersVisibility();
    this.updateTargetsVisibility();
    this.updateParentVisibility();

    if (this.mainContentElement) {
      this.updateMainContentVisibility();
    }
  }

  updateTriggersVisibility() {
    const { target } = this.state;

    const { activeClassName } = this;

    this.triggerTargets.forEach((el) => {
      const currentTarget = getPrefixedDatasetAttribute(el, 'target');

      if (currentTarget === target) {
        el.classList.add(activeClassName);
      } else {
        el.classList.remove(activeClassName);
      }
    });
  }

  updateTargetsVisibility() {
    const {
      target,
      behavior,
    } = this.state;

    const {
      activeClassName,
      inactiveClassName,
    } = this;

    const hasDisableFormElementsBehavior = behavior === DISABLE_FORM_ELEMENTS_BEHAVIOR;

    this.targetTargets.forEach((el) => {
      const currentTarget = getPrefixedDatasetAttribute(el, 'target');

      if (currentTarget === target) {
        if (inactiveClassName) {
          el.classList.remove(inactiveClassName);
        } else {
          el.classList.add(activeClassName);
        }
        if (hasDisableFormElementsBehavior) {
          this.enableFormElements(el);
        }
        this.focusOnFocusElement(el);
      } else {
        if (inactiveClassName) {
          el.classList.add(inactiveClassName);
        } else {
          el.classList.remove(activeClassName);
        }
        if (hasDisableFormElementsBehavior) {
          this.disableFormElements(el);
        }
      }
    });
  }

  updateParentVisibility() {
    const { target } = this.state;

    const classList = this.element.classList; // eslint-disable-line prefer-destructuring

    const currentTargetClassNames = Array.from(classList).filter(c => c.match(PARENT_TARGET_ACTIVE_CLASS_NAME_REGEXP));

    if (currentTargetClassNames.length) classList.remove(...currentTargetClassNames);

    if (target) {
      classList.add(PARENT_ACTIVE_CLASS_NAME);
      classList.add(`${PARENT_TARGET_ACTIVE_CLASS_NAME_BASE}${target}`);
    } else {
      classList.remove(PARENT_ACTIVE_CLASS_NAME);
    }
  }

  updateMainContentVisibility() {
    const {
      target,
      behavior,
    } = this.state;

    const { mainContentElement } = this;

    if (target && behavior === HIDE_MAIN_CONTENT_BEHAVIOR) {
      mainContentElement.setAttribute('hidden', '');
    } else {
      mainContentElement.removeAttribute('hidden');
    }
  }

  focusOnFocusElement(containerElement) {
    const focusElement = containerElement.querySelector(FOCUS_SELECTOR);

    if (focusElement) focusElement.focus();
  }

  disableFormElements(containerElement) {
    const formElements = Array.from(containerElement.querySelectorAll('input:not([disabled]),select:not([disabled]),textarea:not([disabled])'));

    formElements.forEach((element) => {
      element.setAttribute('disabled', true);
      element.classList.add(FORM_ELEMENTS_DISABLED_CLASS_NAME);
    });
  }

  enableFormElements(containerElement) {
    const formElements = Array.from(containerElement.querySelectorAll(`.${FORM_ELEMENTS_DISABLED_CLASS_NAME}`));

    formElements.forEach((element) => {
      element.removeAttribute('disabled');
      element.classList.remove(FORM_ELEMENTS_DISABLED_CLASS_NAME);
    });
  }
}

export {
  ToggleContentController,
  ToggleContentController as default,
};
