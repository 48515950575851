/*
 * Form With Stripe Card Input Controller
 *
 * Dependencies in view:
 * - Within form: <%= render "shared/stripe/hidden_fields" %>
 * - Within form: <%= render "shared/stripe/card_input" %>
 * - Within view: <%= render "shared/stripe/scripts" %>
 */

import { Controller } from 'stimulus';

import getCurrentBreakpoint from '../lib/get-current-breakpoint';

const CURRENT_BREAKPOINT = getCurrentBreakpoint();
const LINE_HEIGHT = ({
  '0px': '1.45',
  '600px': '1.50',
  '900px': '1.55',
  '1200px': '1.60',
})[CURRENT_BREAKPOINT];
const FONT_SIZE = ({
  '0px': '16px',
  '600px': '17px',
  '900px': '18px',
  '1200px': '19px',
})[CURRENT_BREAKPOINT];
const STYLE = {
  base: {
    lineHeight: LINE_HEIGHT,
    fontSize: FONT_SIZE,
  },
};

class FormWithStripeCardInputController extends Controller {
  static targets = ['card', 'error', 'intentSecret', 'name', 'email', 'submitButton'];

  initialize() {
    this.stripe = window.Stripe(window.Unicef.Stripe.publishableKey);
    this.elements = this.stripe.elements({ locale: 'sv' });
  }

  connect() {
    this.card = this.elements.create('card', { style: STYLE });
    this.stripeSource = null;

    this.card.mount(this.cardTarget);

    this.hideError();

    this.setupFormSubmitEventListener();
  }

  setupFormSubmitEventListener() {
    this.element.addEventListener('submit', (event) => {
      event.preventDefault();

      this.submitButtonTarget.disabled = true;

      this.stripe.confirmCardSetup(
        this.intentSecretTarget.value,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.nameTarget.value || null,
              email: this.emailTarget.value,
            },
          },
        },
      ).then((result) => {
        if (result.error) {
          this.displayError(result.error.message);
          this.submitButtonTarget.disabled = false;
        } else {
          this.element.submit();
        }
      });
    });
  }

  displayError(message) {
    this.errorTarget.textContent = message;
    this.errorTarget.hidden = false;
  }

  hideError() {
    this.errorTarget.textContent = '';
    this.errorTarget.hidden = true;
  }
}

export {
  FormWithStripeCardInputController,
  FormWithStripeCardInputController as default,
};
