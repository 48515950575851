import { definitionsFromContext } from 'stimulus/webpack-helpers';

import { UnicefApp } from '../lib/unicef-app';
import Logger from '../modules/logger';
import * as globals from '../lib/globals';

(() => {
  window.Unicef = (window.Unicef || {});

  Object.keys(globals).forEach((key) => {
    window.Unicef[key] = globals[key];
  });
})();

const application = UnicefApp.start();
const context = require.context('../controllers', true, /.js$/);
application.load(definitionsFromContext(context));

const defaultErrorHandler = application.handleError.bind(application);

const sentryErrorHandler = (error, message, detail = {}) => {
  defaultErrorHandler(error, message, detail);
  Logger.logStimulus(error, message, detail);
};

application.handleError = sentryErrorHandler;
