import { html } from 'common-tags';
import merge from 'lodash/merge';

const DEFAULT_OPTIONS = {
  padded: true,
  header: {
    heading: null,
    close: 'Stäng',
  },
  footer: null,
};

function footerAttributes(options) {
  const attributes = {
    'data-controller': [],
  };

  if (options.dissmissableId) {
    attributes['data-controller'].push('permanently-dismissable');
    attributes['data-permanently-dismissable-id'] = options.dissmissableId;
  }

  if (options.checkboxToggleId) {
    attributes['data-controller'].push('checkbox-toggle');
    attributes['data-checkbox-toggle-id'] = options.checkboxToggleId;
  }

  attributes['data-controller'].join(' ');

  return Object.entries(attributes).map(entry => `${entry[0]}="${entry[1]}"`).join(' ');
}

function modalTemplate(content = '', optionsArg = {}) {
  const options = merge({}, DEFAULT_OPTIONS, optionsArg);

  return html`
    <div class="u-v2">
      <div class="Modal" role="dialog">
        <div class="Modal-content">
          ${options.header && (html`
            <header class="Modal-header">
              ${options.header.heading && (html`
                <h1 class="Heading Heading--deca js-modal-heading">${options.header.heading}</h1>
              `)}

              ${options.header.close && (html`
                <button class="Modal-headerClose js-modal-close">${options.header.close}</button>
              `)}
            </header>
          `)}

          <main class="Modal-main${options.padded ? ' is-padded' : ''}">
            ${content}
          </main>

          ${options.footer && (html`
            <footer class="Modal-footer" ${footerAttributes({ dissmissableId: options.footer.dismissableId, checkboxToggleId: options.footer.checkboxToggleId })}>
              ${options.footer.accept && (html`
                <button class="Button js-modal-close" data-action="permanently-dismissable#dismiss checkbox-toggle#checkAction">${options.footer.accept}</button>
              `)}

              ${options.footer.cancel && (html`
                <div class="Modal-footerCancel">
                  eller <button class="Modal-footerCancelButton js-modal-close">${options.footer.cancel}</button>
                </div>
              `)}
            </footer>
          `)}
        </div>
      </div>
    </div>
  `;
}

export default modalTemplate;
