import { Controller } from 'stimulus';

import INPUT_EVENT_NAME from '../lib/input-event-name';
import trackEvent from '../modules/track-event';

const RANGE_SLIDER_CHANGE_EVENT_NAME = 'range-slider-change';
const INPUT_EVENT = new CustomEvent(INPUT_EVENT_NAME);

class RecurringDonationsController extends Controller {
  static targets = ['children', 'amount', 'amountInput', 'product', 'productInput', 'rangeSlider']

  initialize() {
    this.onSliderChange = this.onSliderChange.bind(this);
    this.setTrackLabel();
  }

  connect() {
    window.addEventListener(RANGE_SLIDER_CHANGE_EVENT_NAME, this.onSliderChange, false);
  }

  onSliderChange(event) {
    const hiddenSliders = this.rangeSliderTargets.filter(el => this.isHidden(el));
    const amount = event.detail.customValues.valueOutput;

    this.amountInputTarget.value = amount;

    this.amountTargets.forEach((target) => {
      target.innerHTML = amount; // eslint-disable-line no-param-reassign
    });

    this.setNumberOfChildren(event.detail.customValues.value);
    this.setHiddenSliders(hiddenSliders, event.detail.value);
    this.setTrackLabel();
  }

  onAmountChange() {
    const amount = this.amountInputTarget.value;

    trackEvent('Recurring Donations - Amount change', {
      amount,
    });
  }

  productChange(event) {
    this.showProduct(event.target.value);
    this.setTrackLabel();
    trackEvent('Recurring Donations - Product change', {
      product: event.target.value,
    });
  }

  showProduct(productId) {
    const productEl = this.productTargets.filter(el => el.getAttribute('data-product-id') === productId)[0];

    this.productTargets.forEach((el) => {
      el.classList.add('is-hidden');
    });

    productEl.classList.remove('is-hidden');
    productEl.querySelector('input').dispatchEvent(INPUT_EVENT);
  }

  setHiddenSliders(sliders, value) {
    sliders.forEach((slider) => {
      slider.querySelector('input').value = value; // eslint-disable-line no-param-reassign
    });
  }

  setNumberOfChildren(value) {
    this.childrenTargets.forEach((el) => {
      el.innerHTML = value; // eslint-disable-line no-param-reassign
    });
  }

  setTrackLabel() {
    const product = this.productInputTargets.find(el => el.checked).getAttribute('data-product-name');
    const amount = this.amountInputTarget.value;

    this.element.setAttribute('data-track-label', `${product}+${amount}`);
  }

  isHidden(el) {
    return el.offsetParent === null;
  }
}

export {
  RecurringDonationsController,
  RecurringDonationsController as default,
};
