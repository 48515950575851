import { Controller } from 'stimulus';

import fetchWithDefaults from '../lib/fetch-with-defaults';

class ContentLoaderController extends Controller {
  connect() {
    this.url = this.data.get('url');
    this.load();
  }

  load() {
    fetchWithDefaults(this.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} (${response.statusText})`);
        }

        return response.text();
      })
      .then(this.onResponseHTML.bind(this))
      .catch(this.onFetchError.bind(this));
  }

  onResponseHTML(html) {
    this.element.innerHTML = html;
  }

  onFetchError(err) {
    if (console && console.error) console.error(err); // eslint-disable-line no-console
  }
}

export {
  ContentLoaderController,
  ContentLoaderController as default,
};
