/*
 * Track Event Controller
 *
 * Usage:
 * Alternative 1:
 * When you want to track events "on load"
 * <div data-controller="track-event" data-track-event-type="Giftshop - Checkout completed" data-track-event-properties='{"itemCount": 4}'></div>
 *
 * Alternative 2:
 * When you want to track events on an action
 * <button data-controller="track-event" data-action="track-event#trackEvent" data-track-event-type="Giftshop - Click buy gift in modal" data-track-event-properties='{"itemCount": 4}'></button>
 *
 * Value of data-track-event-properties attribute should be JSON parseable, or omitted.
 */

import { Controller } from 'stimulus';

import trackEvent from '../modules/track-event';

const TRACK_CALLBACK_TIMEOUT = 2500; // ms

function newTabClick(event) {
  return (event.which > 1
    || event.altKey
    || event.ctrlKey
    || event.metaKey
    || event.shiftKey
  );
}

function track(element, event) {
  const type = element.getAttribute('data-track-event-type');
  const properties = JSON.parse(element.getAttribute('data-track-event-properties') || null);
  const href = element.getAttribute('href');

  let callback = null;
  let callbackTimeout = null;

  if (element.tagName.toLowerCase() === 'a' && href && !newTabClick(event)) {
    event.preventDefault();

    callbackTimeout = window.setTimeout(() => {
      window.location = href;
    }, TRACK_CALLBACK_TIMEOUT);

    callback = () => {
      window.clearTimeout(callbackTimeout);
      window.location = href;
    };
  }

  if (type) {
    trackEvent(type, properties, callback);
  } else {
    throw new Error('Type needs to be present');
  }
}

class TrackEventController extends Controller {
  connect() {
    const hasTrackEventAction = (this.element.getAttribute('data-action') || '').includes('track-event#trackEvent');
    const type = this.data.get('type');

    if (!hasTrackEventAction && type) {
      track(this.element);
    }
  }

  trackEvent(event) {
    track(event.currentTarget, event);
  }
}

export default TrackEventController;
