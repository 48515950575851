import { Controller } from 'stimulus';

import { push as gtm_push } from '../modules/gtm';

export default class extends Controller {

  static values = { json: Object }
  connect() {
    const event = JSON.parse(this.data.get("json"))
    gtm_push(event)
  }
}
