import { Controller } from 'stimulus';

import castAsBoolean from '../lib/cast-as-boolean';
import Modal from '../modules/modal';

import trackEvent from '../modules/track-event';
import validateCivicNumber from '../lib/validate-civic-number';
import {
  BANK_ACCOUNTS_LOOKUP_UPDATE_MODAL_EVENT,
  BANK_ACCOUNTS_LOOKUP_CHOOSE_ACCOUNT_EVENT,
} from '../constants/bank-accounts-lookup';

const BANKS_LIST_SELECTOR = '.js-fetch-bank-account-banks';
const OTHER_BANKS_SELECTOR = '.js-fetch-bank-account-other-banks';
const OTHER_BANK_BUTTON_SELECTOR = '.js-fetch-bank-account-other-bank';
const ENTER_ACCOUNT_MANUALLY_BUTTON_SELECTOR = '.js-fetch-bank-account-manual';
const TOGGLE_VISIBILITY_CLASS = 'is-hidden';

class BankAccountsLookupController extends Controller {
  static targets = ['civicNumber', 'civicNumberMessage', 'bankAccount', 'lookupButton', 'lookupText'];

  initialize() {
    this.onOtherBankButtonClick = this.onOtherBankButtonClick.bind(this);
    this.onEnterAccountManuallyButtonClick = this.onEnterAccountManuallyButtonClick.bind(this);
    this.onAccountButtonClick = this.onAccountButtonClick.bind(this);
    this.onNewModalData = this.onNewModalData.bind(this);
  }

  connect() {
    this.modal = null;
    this.newPath = this.element.getAttribute('data-new-bank-accounts-lookup-path');
    this.submitFormOnComplete = castAsBoolean(this.data.get('submit-form-on-complete'));

    this.featureDetect();
    this.setupEventListeners();
  }

  featureDetect() {
    const hasSupport = typeof new XMLHttpRequest().responseURL === 'string'; // IE 11

    if (!hasSupport) {
      this.lookupButtonTarget.classList.add(TOGGLE_VISIBILITY_CLASS);
      this.lookupTextTarget.classList.add(TOGGLE_VISIBILITY_CLASS);
    }
  }

  showModal(content, heading) {
    if (this.modal) {
      this.modal.remove();
    }

    this.modal = new Modal(content, {
      addHideBindings: false,
      template: {
        padded: false,
        header: {
          heading,
          close: 'Avbryt',
        },
      },
    });

    this.modal.show();
  }

  beginLookup(event) {
    const civicNumber = this.civicNumberTarget.value;
    const validCivicNumber = validateCivicNumber(this.civicNumberTarget.value, {
      target: this.civicNumberMessageTarget,
    });

    event.preventDefault();

    if (validCivicNumber) {
      this.renderBanks(civicNumber);
    } else {
      this.civicNumberTarget.focus();
    }

    trackEvent('Bank accounts lookup - Clicked fetch accounts button', {
      withValidCivicNumber: validCivicNumber,
    });
  }

  async renderBanks(civicNumber) {
    const url = `${this.newPath}?bank_accounts_lookup[civic_number]=${civicNumber}`;
    const resp = await fetch(url);

    if (!resp.ok) {
      const error = new Error(`request failed with status ${resp.status}: ${resp.statusText}`);
      throw error;
    }

    const content = await resp.text();

    this.showModal(content, 'Välj din bank');
    this.setupBankEventListeners();
    this.setModalCloseAttributes({
      'data-controller': 'track-event',
      'data-action': 'track-event#trackEvent',
      'data-track-event-type': 'Bank accounts lookup - Aborted',
    });
  }

  onOtherBankButtonClick() {
    document.querySelector(BANKS_LIST_SELECTOR).classList.toggle(TOGGLE_VISIBILITY_CLASS);
    document.querySelector(OTHER_BANKS_SELECTOR).classList.toggle(TOGGLE_VISIBILITY_CLASS);

    this.modal.updateHeading('Annan bank');
  }

  onEnterAccountManuallyButtonClick() {
    this.modal.hide();
    this.bankAccountTarget.focus();
  }

  onAccountButtonClick(event) {
    this.bankAccountTarget.value = event.detail.accountNumber;
    this.modal.hide();

    if (this.submitFormOnComplete) {
      this.element.submit();
    }
  }

  onNewModalData(event) {
    this.modal.updateHeading(event.detail.heading);

    this.modal.togglePadded(event.detail.padded);
  }

  setupEventListeners() {
    window.addEventListener(BANK_ACCOUNTS_LOOKUP_UPDATE_MODAL_EVENT, this.onNewModalData, false);
    window.addEventListener(BANK_ACCOUNTS_LOOKUP_CHOOSE_ACCOUNT_EVENT, this.onAccountButtonClick, false);
    document.addEventListener('click', (event) => {
      if (event.target.classList.contains(ENTER_ACCOUNT_MANUALLY_BUTTON_SELECTOR.substring(1))) {
        this.onEnterAccountManuallyButtonClick();
      }
    });
  }

  setupBankEventListeners() {
    document.querySelector(OTHER_BANK_BUTTON_SELECTOR).addEventListener('click', this.onOtherBankButtonClick, false);
  }

  setModalCloseAttributes(attributes) {
    this.modal.closeButtons.forEach((el) => {
      Object.keys(attributes).forEach((attribute) => {
        el.setAttribute(attribute, attributes[attribute]);
      });
    });
  }
}

export {
  BankAccountsLookupController,
  BankAccountsLookupController as default,
};
