import { Controller } from 'stimulus';
import { push as gtmPush } from '../modules/gtm';

export default class extends Controller {
  static targets = ['quantity', 'cartQuantity'];

  static values = { currentQuantity: Number };

  initialize() {
    this.currentQuantity = this.cartQuantity;
  }

  get virtualGift() {
    return JSON.parse(this.data.get('virtual-gift'));
  }

  get quantity() {
    if (!this.hasQuantityTarget) return 1;

    const quantity = parseInt(this.quantityTarget.value, 10);

    if (!Number.isInteger(quantity)) throw new Error('Parameter is not a number!');

    return quantity;
  }

  get cartQuantity() {
    if (!this.hasCartQuantityTarget) return 0;

    const cartQuantity = parseInt(this.cartQuantityTarget.value, 10);

    if (!Number.isInteger(cartQuantity)) throw new Error('Parameter is not a number!');

    return cartQuantity;
  }

  click() {
    const event = {
      event: 'EECproductClick',
      ecommerce: {
        currencyCode: 'SEK',
        click: {
          actionField: { list: this.data.get('list') },
          products: [
            {
              ...this.toBaseProduct(),
              list: this.data.get('list'),
              position: this.data.get('position'),
            },
          ],
        },
      },
    };

    gtmPush(event);
  }

  detailView() {
    const event = {
      event: 'EECproductDetailView',
      ecommerce: {
        currencyCode: 'SEK',
        detail: {
          actionField: {
            list: this.data.get('list'),
          },
          products: [
            {
              ...this.toBaseProduct(),
            },
          ],
        },
      },
    };

    gtmPush(event);
  }

  addToCart() {
    const isModal = () => this.data.get('type') === 'modal';
    const isModalOpen = () => this.element.parentNode.parentNode.parentNode.classList.contains('is-open');

    if (isModal() && !isModalOpen()) return;

    this.increaseProductInCart(this.quantity);
  }

  removeFromCart() {
    if (this.currentQuantity === 1) { return; }
    const event = {
      event: 'EECremoveFromCart',
      ecommerce: {
        currencyCode: 'SEK',
        remove: {
          products: [
            {
              ...this.toBaseProduct(),
              quantity: this.quantity,
            },
          ],
        },
      },
    };
    this.currentQuantity = this.currentQuantity - this.quantity;
    gtmPush(event);
  }

  clearProductFromCart() {
    const event = {
      event: 'EECremoveFromCart',
      ecommerce: {
        currencyCode: 'SEK',
        remove: {
          products: [
            {
              ...this.toBaseProduct(),
              quantity: this.cartQuantity,
            },
          ],
        },
      },
    };
    this.currentQuantity = 0;
    gtmPush(event);
  }

  handleInputChange(e) {
    const before = this.virtualGift.quantity;
    const current = e.target.value;

    if (before > current) {
      this.decreaseProductInCart(before - current);
    } else if (before < current) {
      this.increaseProductInCart(current - before);
    }
  }

  handleDecreaseProductInCart() {
    this.decreaseProductInCart(this.cartQuantity);
  }

  handleIncreaseProductInCart() {
    this.increaseProductInCart(this.cartQuantity);
  }

  decreaseProductInCart(q) {
    if (this.currentQuantity === 1) { return; }
    const event = {
      event: 'EECremoveFromCart',
      ecommerce: {
        currencyCode: 'SEK',
        remove: {
          products: [
            {
              ...this.toBaseProduct(),
              quantity: q,
            },
          ],
        },
      },
    };

    this.currentQuantity = this.currentQuantity - q;
    gtmPush(event);
  }

  increaseProductInCart(q) {
    const event = {
      event: 'EECaddToCart',
      ecommerce: {
        currencyCode: 'SEK',
        add: {
          products: [
            {
              ...this.toBaseProduct(),
              quantity: q,
            },
          ],
        },
      },
    };

    this.currentQuantity = this.currentQuantity + q;
    gtmPush(event);
  }

  toBaseProduct() {
    return {
      name: this.virtualGift.name,
      id: this.virtualGift.id,
      price: this.virtualGift.price,
      brand: 'Unicef',
      category: 'Gifts',
    };
  }
}
