import { Controller } from 'stimulus';
import Logger from '../modules/logger';
import trackEvent from '../modules/track-event';
import poll from '../lib/poll';
import openBankIdApp from '../lib/open-bank-id-app';

import {
  BANK_ACCOUNTS_LOOKUP_UPDATE_MODAL_EVENT,
  BANK_ACCOUNTS_LOOKUP_CHOOSE_ACCOUNT_EVENT,
} from '../constants/bank-accounts-lookup';

class BankAccountsFetchController extends Controller {
  connect() {
    this.lastPollContent = null;
  }

  pollForAccounts(url) {
    return poll({
      url,
      responseType: 'text',
      compareFn: (response, data) => {
        const state = response.headers.get('X-Lookup-State');

        if (data !== this.lastPollContent) {
          this.handlePollResponse(state, data);
        }

        this.lastPollContent = data;
        return state !== 'pending';
      },
      timeout: 300000,
      interval: 3000,
    });
  }

  handlePollResponse(state, content) {
    let heading;

    if (state === 'pending') {
      heading = 'Godkänn hämtning';
    } else if (state === 'completed') {
      heading = 'Välj konto';
    } else { // errored or failed
      heading = 'Hämtningen har avbrutits';
    }

    this.updateModalContent(content, heading, false);
  }

  onCreate(event) {
    const xhr = event.detail[2];

    Promise.resolve()
      .then(this.pollForAccounts.bind(this, xhr.responseURL))
      .catch(this.onPollError.bind(this));
  }

  onPollError(err) {
    Logger.error(err);

    this.updateModalWithGenericError();
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    if (xhr.status === 500) {
      Logger.error(status);
    } else {
      Logger.info(`Bank accounts lookup failure: ${data}`);
    }

    this.updateModalWithGenericError();
  }

  updateModalWithGenericError() {
    const content = '<p>Tyvärr fungerar det inte att hämta dina konton just nu. Var vänlig <button class="u-buttonAsLink js-fetch-bank-account-manual">ange kontonumret manuellt</button>.</p>';

    this.updateModalContent(content, 'Hämtningen har avbrutits', true);

    trackEvent('Bank accounts lookup - Saw error');
  }

  updateModalContent(content, heading, padded) {
    const updateModalEvent = new CustomEvent(BANK_ACCOUNTS_LOOKUP_UPDATE_MODAL_EVENT, {
      detail: { heading, padded },
    });

    this.element.innerHTML = content;

    window.dispatchEvent(updateModalEvent);
  }

  onAccountButtonClick(event) {
    const accountNumber = event.currentTarget.getAttribute('data-account-number');
    const chooseAccountEvent = new CustomEvent(BANK_ACCOUNTS_LOOKUP_CHOOSE_ACCOUNT_EVENT, {
      detail: { accountNumber },
    });

    window.dispatchEvent(chooseAccountEvent);
  }

  openBankIdApp(event) {
    const autoStartToken = event.currentTarget.getAttribute('data-auto-start-token');

    event.preventDefault();

    openBankIdApp(autoStartToken, false);
  }
}

export {
  BankAccountsFetchController,
  BankAccountsFetchController as default,
};
