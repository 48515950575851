const BREAKPOINTS = ['1200px', '900px', '600px', '0px'];

function getCurrentBreakpoint() {
  let i = 0;
  let brakepoint = null;

  while (true) { // eslint-disable-line no-constant-condition
    const shouldBreak = window.matchMedia(`(min-width: ${BREAKPOINTS[i]})`).matches;


    if (shouldBreak) {
      brakepoint = BREAKPOINTS[i];
      break;
    }

    i += 1;
  }

  return brakepoint;
}

export default getCurrentBreakpoint;
