import { html } from 'common-tags';

export function collageDonorTemplate(donor) {
  return html`
    <li class="Collage-item js-pack-item${donor.message ? ' Collage-item--withMessage' : ''}${donor.new ? ' is-new' : ''}">
      ${donor.avatar_url ? (html`<div><img src="${donor.avatar_url}" alt="Profilbild" class="Collage-itemImage"></div>`) : ''}
      ${donor.message ? (html`<div class="Collage-itemMessage Meta"><p>${donor.message}</p></div>`) : ''}
    </li>
  `;
}

export function collageDonorsTemplate(donors) {
  return html`
    <ul class="Collage-list js-pack" data-target="donors.listInner">
      <li class="Collage-itemStamp js-pack-stamp" aria-hidden="true"></li>
      ${donors.map(donor => collageDonorTemplate(donor, false)).join('')}
    </ul>
  `;
}
