import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['digital', 'paper'];

  toggleDigital() {
    this.paperTargets.map(target => target.classList.add('is-hidden'));
    this.digitalTargets.map(target => target.classList.remove('is-hidden'));
  }

  togglePaper() {
    this.paperTargets.map(target => target.classList.remove('is-hidden'));
    this.digitalTargets.map(target => target.classList.add('is-hidden'));
  }
}
