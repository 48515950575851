import Logger from '../modules/logger';

const JSON_TYPE = 'json';
const TEXT_TYPE = 'text';

class PollError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PollError';
  }
}

function poll({
  url = null,
  interval = 1000,
  timeout = 30000,
  responseType = JSON_TYPE,
  headers = {},
  compareFn = response => !!response.ok,
}) {
  const start = new Date();
  const maxFetchFailures = 3;
  let fetchFailures = 0;
  let canceled = false;
  let pollStartTime;

  const cancelFunc = () => { canceled = true; };

  return [new Promise((resolve, reject) => {
    function handleError(response) {
      const { status, statusText } = response;

      return reject(new PollError(`${status} ${statusText}`));
    }

    async function checkResult(response) {
      const elapsedtime = new Date().getTime() - pollStartTime;
      if (elapsedtime > 1000) Logger.warning('Request took more than 1 second', {}, { round_trip_time: elapsedtime });

      fetchFailures = 0;

      if (!response.ok) {
        return handleError(response);
      }

      let responseData;

      switch (responseType) {
        case JSON_TYPE:
          try {
            responseData = await response.json();
          } catch (e) {
            responseData = {};
          }
          break;
        case TEXT_TYPE:
        default:
          responseData = await response.text();
          break;
      }

      try {
        const compareResult = compareFn(response, responseData);

        if (compareResult instanceof Error) {
          return reject(compareResult);
        }

        if (compareResult) {
          return resolve(responseData);
        }
      } catch (e) {
        return reject(e);
      }

      if (new Date() - start > timeout) {
        return handleError({ status: 408, statusText: 'Poll timeout' });
      }

      // eslint-disable-next-line no-use-before-define
      window.setTimeout(performPoll, interval);

      return false;
    }

    function performPoll() {
      if (canceled) return;

      pollStartTime = new Date().getTime();
      fetch(url, {
        credentials: 'same-origin',
        headers,
      }).then(checkResult, () => {
        const elapsedtime = new Date().getTime() - pollStartTime;
        if (elapsedtime > 1000) Logger.warning('Request took more than 1 second', {}, { round_trip_time: elapsedtime });
        if (canceled) return;
        if (fetchFailures < maxFetchFailures) {
          performPoll();
          fetchFailures += 1;
        } else {
          handleError({ status: 500, statusText: 'Network error after max attempts' });
        }
      });
    }

    performPoll();
  }), cancelFunc];
}

function pollForExpectedStatus(pollUrl, options) {
  const expectedStatus = options.expectedStatus || 200;

  return poll({
    ...options,
    url: pollUrl,
    compareFn: response => response.status === expectedStatus,
  });
}

export { pollForExpectedStatus, poll as default };
