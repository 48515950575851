/*
 * Toggle Class Controller
 */

import { Controller } from 'stimulus';

class DigitalBankDetails extends Controller {
  static targets = ['enterManuallyButton', 'node'];

  show() {
    this.nodeTarget.classList.remove('u-hide');
  }

  hide() {
    this.nodeTarget.classList.add('u-hide');
  }

  toggle(e) {
    if (e.target.checked) {
      this.nodeTarget.classList.add('u-hide');
    } else {
      this.nodeTarget.classList.remove('u-hide');
    }
  }
}

export {
  DigitalBankDetails,
  DigitalBankDetails as default,
};
