import { Controller } from 'stimulus';

const IS_SELECTED_CLASS = 'is-selected';
const OPTION_LIST_INITIAL_CHECKED_EVENT_NAME = 'option-list-initial-checked';
const OPTION_LIST_CHANGE_EVENT_NAME = 'option-list-change';

class OptionListController extends Controller {
  static targets = ['radio'];

  connect() {
    this.setInitialSelectedOption();
  }

  setInitialSelectedOption() {
    this.radioTargets.forEach((target) => {
      if (target.checked) {
        target.parentNode.classList.add(IS_SELECTED_CLASS);

        const optionListChangeEvent = new CustomEvent(OPTION_LIST_INITIAL_CHECKED_EVENT_NAME, {
          detail: {
            label: document.querySelector(`[for=${target.id}]`).innerText,
          },
        });

        window.dispatchEvent(optionListChangeEvent);
      }
    });
  }

  change(event) {
    const optionListChangeEvent = new CustomEvent(OPTION_LIST_CHANGE_EVENT_NAME, {
      detail: {
        label: document.querySelector(`[for=${event.target.id}]`).innerText,
      },
    });
    event.target.parentNode.classList.add(IS_SELECTED_CLASS);

    window.dispatchEvent(optionListChangeEvent);

    this.radioTargets.forEach((target) => {
      if (target !== event.target) {
        target.parentNode.classList.remove(IS_SELECTED_CLASS);
      }
    });
  }
}

export {
  OptionListController,
  OptionListController as default,
};
