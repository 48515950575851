import { Controller } from 'stimulus';

import validateCivicNumber from '../lib/validate-civic-number';

const HIDDEN_CLASS_NAME = 'is-hidden';

class CivicNumberInput extends Controller {
  static targets = ['message'];

  blur(event) {
    if (event.target.value) {
      validateCivicNumber(event.target.value, {
        target: this.messageTarget,
      });
    }
  }

  receive(event) {
    if (!this.messageTarget.classList.contains(HIDDEN_CLASS_NAME)) {
      validateCivicNumber(event.target.value, {
        target: this.messageTarget,
      });
    }
  }

  hideValidationMessage() {
    this.messageTarget.classList.add(HIDDEN_CLASS_NAME);
  }
}

export {
  CivicNumberInput,
  CivicNumberInput as default,
};
