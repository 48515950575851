import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

const RESIZE_DEBOUNCE_DELAY = 500;

class VideoController extends Controller {
  connect() {
    this.attributes = this.data.get('attributes');
    this.formats = JSON.parse(this.data.get('formats'));
    this.src = this.getSrc();

    this.onResize = debounce(this.onResize.bind(this), RESIZE_DEBOUNCE_DELAY);

    window.addEventListener('resize', this.onResize, false);

    this.render();
  }

  onResize() {
    const newSrc = this.getSrc();

    if (newSrc !== this.src) {
      this.src = newSrc;
      this.render();
    }
  }

  getSrc() {
    return this.formats.find(format => window.matchMedia(format.media).matches).src;
  }

  render() {
    this.element.innerHTML = `<video src="${this.src}" ${this.attributes}></video>`;
  }
}

export {
  VideoController,
  VideoController as default,
};
