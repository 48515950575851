import { Controller } from 'stimulus';
import { strftime } from 'strftime';
import jsonp from 'jsonp';
import { blogFeedPostTemplate } from '../templates/blog-feed-post';

class BlogPost {
  Locale = {};

  constructor(data) {
    this.Locale['sv-SE'] = {
      date: {
        days: ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'],
        shortDays: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'],

        months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli',
          'augusti', 'september', 'oktober', 'november', 'december'],

        shortMonths: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug',
          'sep', 'okt', 'nov', 'dec'],
        AM: 'AM',
        PM: 'PM',
      },
    };

    // eslint-disable-next-line no-param-reassign
    data.date = this.getParsableDateString(data.date);
    this.data = data;
    this.data.formattedDate = this.getFormattedDate(new Date(data.date));
    this.data.shortExcerpt = this.getShortExcerpt(data.excerpt);
  }

  buildElementString() {
    return blogFeedPostTemplate(this.data);
  }

  getFormattedDate(date) {
    const today = new Date();
    const yesterday = new Date();
    let formattedDate = '';

    yesterday.setDate(today.getDate() - 1);
    const isToday = date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
    const wasYesterday = date.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0);

    if (isToday) {
      formattedDate = 'Idag';
    } else if (wasYesterday) {
      formattedDate = 'Igår';
    } else {
      formattedDate = strftime('%A %d %h', date, this.Locale['sv-SE'].date);
    }

    return formattedDate;
  }

  getShortExcerpt(excerpt) {
    const maxLength = 160;
    let shortExcerpt = excerpt.substr(0, maxLength);

    if (excerpt.length > maxLength) {
      shortExcerpt = `${shortExcerpt}…`;
    }

    return shortExcerpt;
  }

  getParsableDateString(date) {
    return date.replace(/-/g, '/');
  }
}

class BlogFeedController extends Controller {
  initialize() {
    this.postCount = parseInt(this.data.get('post-count'), 10);
    this.posts = [];

    this.fetch();
  }

  fetch() {
    const onReceiveBlogFeed = (err, posts) => {
      posts.slice(0, this.postCount).forEach(post => this.posts.push(new BlogPost(post)));
      this.render();
    };

    const feedEndpoint = 'https://blog.unicef.se/wp-content/uploads/json/feed.json';
    const jsonpCallback = 'UnicefRecieveBlogFeed';

    jsonp(
      feedEndpoint,
      {
        name: jsonpCallback,
      },
      onReceiveBlogFeed,
    );
  }

  render() {
    const html = [];

    this.posts.forEach(post => html.push(post.buildElementString()));

    this.element.insertAdjacentHTML('afterbegin', html.join(''));
  }
}

export {
  BlogFeedController,
  BlogFeedController as default,
};
