function trackEvent(type, properties, callback) {
  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(type, properties, callback);
  } else {
    console.log('Amplitude not loaded', { type, properties }); // eslint-disable-line no-console

    if (typeof callback === 'function') {
      callback();
    }
  }
}

export default trackEvent;
