import { EventBuffer } from '../lib/event-buffer';

import {
  CART_SIZE_HEADER_NAME,
  UPDATE_DONE_EVENT_NAME,
  UPDATE_START_EVENT_NAME,
  NEW_HTML_EVENT_NAME,
  SIZE_CHANGE_EVENT_NAME,
} from '../constants/cart';

const POST = 'post';
const GET = 'get';

const HEADERS = new Headers({
  'X-Requested-With': 'XMLHttpRequest',
});

let fetchCartLoadingState = 0;

function incrementFetchCartLoadingState() {
  fetchCartLoadingState += 1;

  if (fetchCartLoadingState === 1) {
    EventBuffer.trigger(UPDATE_START_EVENT_NAME);
  }
}

function decrementFetchCartLoadingState() {
  fetchCartLoadingState -= 1;

  if (fetchCartLoadingState === 0) {
    EventBuffer.trigger(UPDATE_DONE_EVENT_NAME);
  }
}

function fetchCart({ url, body, method } = {}) {
  const requestMethod = method || (body ? POST : GET);

  incrementFetchCartLoadingState();

  const fetchPromise = new Promise((resolve, reject) => {
    fetch(url, {
      body,
      method: requestMethod,
      headers: HEADERS,
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        return reject(response);
      }

      const cartSize = response.headers.get(CART_SIZE_HEADER_NAME);

      return response.text().then((html) => {
        EventBuffer.trigger(SIZE_CHANGE_EVENT_NAME, cartSize);
        EventBuffer.trigger(NEW_HTML_EVENT_NAME, html);

        return resolve(html);
      });
    }).catch(reject);
  });

  fetchPromise.then(decrementFetchCartLoadingState, decrementFetchCartLoadingState);

  return fetchPromise;
}

const CartHelpers = {
  fetchCart,
};

export {
  fetchCart,
  CartHelpers as default,
};
