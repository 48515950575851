import { Controller } from 'stimulus';

import { EventBuffer } from '../lib/event-buffer';
import poll from '../lib/poll';
import openBankIdApp from '../lib/open-bank-id-app';
import Logger from '../modules/logger';
import strings from '../strings/bank-id.json';
import bankIdReducer from '../reducers/bank-id-reducer';

import {
  BANK_ID_AWAITING_SIGNATURE_STATE,
  BANK_ID_CANCELLING_STATE,
  BANK_ID_CANCELLED_STATE,
  BANK_ID_COMPLETED_STATE,
  BANK_ID_ERROR_EVENT,
  BANK_ID_FAILED_STATE,
  BANK_ID_PENDING_STATE,
  BANK_ID_ENTER_PENDING_STATE_ACTION,
  BANK_ID_ERROR_ACTION,
  BANK_ID_HANDLE_RESPONSE_ACTION,
} from '../constants/bank-id';

const HIDDEN_CLASS_NAME = 'is-hidden';

class BankIdError extends Error {
  constructor(message, fileName, lineNumber, data) {
    super(message, fileName, lineNumber);
    this.data = data;
    this.name = 'BankIdError';
  }
}

function newBankIdError(message, data) {
  return new BankIdError(message, undefined, undefined, data);
}

class BankIdController extends Controller {
  static targets = [
    'waiting',
    'error',
    'preparing',
    'cancelling',
    'completed',
    'signing',
    'statusMessage',
    'errorMessage',
    'cancelButton',
  ];

  state = bankIdReducer();

  initialize() {
    ['clickOpenBankIdApp', 'handleResponse', 'handleError'].forEach((fn) => {
      this[fn] = this[fn].bind(this);
    });
  }

  connect() {
    this.dispatch({ type: BANK_ID_ENTER_PENDING_STATE_ACTION });

    Promise.resolve()
      .then(this.poll.bind(this, { timeout: 300000 }))
      .catch(this.handleError);
  }

  clickOpenBankIdApp(e) {
    e.preventDefault();

    openBankIdApp(this.autoStartToken, true);
  }

  poll(pollOptions = {}) {
    const [promise] = poll({
      url: this.data.get('pollUrl'),
      compareFn: (_response, data) => {
        this.handleResponse(data);

        return this.state.signatureState === BANK_ID_COMPLETED_STATE;
      },
      ...pollOptions,
    });

    return promise;
  }

  handleResponse(response) {
    if (!response) {
      throw newBankIdError('Empty response', response);
    }

    switch (response.state) {
      case BANK_ID_FAILED_STATE:
        throw newBankIdError(response.failure || 'Unknown error', response);
      case BANK_ID_CANCELLED_STATE:
        throw newBankIdError(response.failure || 'Cancelled (server responded without hint code)', response);
      case BANK_ID_AWAITING_SIGNATURE_STATE:
      case BANK_ID_COMPLETED_STATE:
      case BANK_ID_ERROR_EVENT:
      case BANK_ID_CANCELLING_STATE:
      case BANK_ID_PENDING_STATE:
        this.dispatch({
          type: BANK_ID_HANDLE_RESPONSE_ACTION,
          payload: {
            response,
          },
        });
        break;
      default:
        throw newBankIdError('Unknown state', response);
    }
  }

  handleError(err) {
    const errorTags = this.errorTags; // eslint-disable-line prefer-destructuring

    this.dispatch({
      type: BANK_ID_ERROR_ACTION,
      payload: {
        response: (err instanceof Error) ? err.data : err,
      },
    });

    if (err instanceof BankIdError) {
      if (strings.grandIdHints[err.message]) {
        Logger.info(`BankID failure: ${err.message}`, errorTags);
      } else {
        Logger.error(err, errorTags);
      }
    } else if (err instanceof Error) {
      throw err;
    }
  }

  dispatch(action) {
    const previousState = this.state;
    const newState = bankIdReducer(previousState, action);

    this.state = newState;

    if (JSON.stringify(previousState) !== JSON.stringify(newState)) {
      this.render(previousState);
    }
  }

  render(previousState) {
    const { state } = this;

    if (state.statusMessage !== previousState.statusMessage) {
      this.updateStatusMessage(state.statusMessage);
    }

    if (state.errorMessage !== previousState.errorMessage) {
      this.updateErrorMessage(state.errorMessage);
    }

    if (state.signatureState !== previousState.signatureState) {
      switch (state.signatureState) {
        case BANK_ID_PENDING_STATE:
          this.showHideTargets([this.waitingTarget, this.preparingTarget], [this.errorTarget, this.signingTarget, this.completedTarget]);
          break;
        case BANK_ID_AWAITING_SIGNATURE_STATE:
          this.showHideTargets([this.waitingTarget, this.signingTarget], [this.errorTarget, this.preparingTarget, this.completedTarget]);
          break;
        case BANK_ID_CANCELLING_STATE:
          this.showHideTargets(
            [this.cancellingTarget],
            [this.cancelButtonTarget, this.preparingTarget, this.signingTarget, this.errorTarget, this.completedTarget],
          );
          break;
        case BANK_ID_COMPLETED_STATE:
          this.showHideTargets([this.waitingTarget, this.completedTarget], [this.errorTarget, this.preparingTarget, this.signingTarget]);
          break;
        case BANK_ID_FAILED_STATE:
        default:
          this.showHideTargets([this.errorTarget], [this.waitingTarget]);
          break;
      }

      if (state.signatureState === BANK_ID_COMPLETED_STATE) {
        window.location.replace(state.returnUrl);
      }

      if (state.signatureState === BANK_ID_FAILED_STATE) {
        EventBuffer.trigger(BANK_ID_ERROR_EVENT);
      }
    }
  }

  updateErrorMessage(message) {
    if (message) {
      this.errorMessageTarget.innerText = message;
    }
  }

  updateStatusMessage(message) {
    if (message) {
      this.statusMessageTarget.innerText = message;
    }
  }

  showHideTargets(showTargets = [], hideTargets = []) {
    showTargets.forEach(t => t.classList.remove(HIDDEN_CLASS_NAME));
    hideTargets.forEach(t => t.classList.add(HIDDEN_CLASS_NAME));
  }

  get errorTags() {
    /* eslint-disable no-underscore-dangle */

    if (!this._errorTags) {
      try {
        this._errorTags = JSON.parse(this.data.get('errorTags'));
      } catch (e) {
        this._errorTags = null;
      }
    }

    return this._errorTags;
  }
}

export {
  BankIdController,
  BankIdController as default,
};
