import { Controller } from 'stimulus';

import { push as gtmPush } from '../modules/gtm';

export default class extends Controller {
  static targets = ['radioAmount']

  get selectedRadio() {
    return this.radioAmountTargets.find(x => x.checked);
  }

  push() {
    const donationAmount = this.hasRadioAmountTarget && this.selectedRadio
      ? this.selectedRadio.value
      : parseInt(this.data.get('amount'), 10) || undefined;

    const event = {
      event: 'gaEvent',
      eventCategory: 'Donations',
      eventAction: this.data.get('event-action') || undefined,
      eventLabel: this.data.get('event-label') || undefined,
      eventValue: undefined,
      donationType: this.data.get('donation-type') || undefined,
      donatorType: this.data.get('donor-type') || undefined,
      donationAmount,
      otherAmount: this.data.get('custom-amount') || undefined,
      paymentType: this.data.get('payment-method') || undefined,
      errorMessage: this.data.get('errors') || undefined,
      donationPurpose: this.data.get('purpose') || undefined,
    };

    gtmPush(event);
  }
}
