export const isVisibleInViewport = (element) => {
  const bounding = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  return (
    bounding.top >= 0
    && bounding.left >= -10
    && bounding.bottom <= viewportHeight
    && bounding.right <= viewportWidth + 10
  );
};

export default isVisibleInViewport;
