import isValidPersonalIdentityNumber from './is-valid-personal-identity-number';
import strings from '../strings/civic-number.json';

const HIDDEN_CLASS_NAME = 'is-hidden';

function setValidState(target) {
  target.classList.add(HIDDEN_CLASS_NAME);
}

function setInvalidState(target) {
  target.innerHTML = strings.messages.invalid; // eslint-disable-line no-param-reassign
  target.classList.remove(HIDDEN_CLASS_NAME);
}

function validateCivicNumber(valueArg, options = {}) {
  const value = valueArg.replace(/\D/g, '');
  const { length } = value;
  let valid = false;

  if (length === 12 && isValidPersonalIdentityNumber(value)) {
    setValidState(options.target);
    valid = true;
  } else {
    setInvalidState(options.target);
  }

  return valid;
}

export default validateCivicNumber;
