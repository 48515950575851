import axios from 'axios';
import { html } from 'common-tags';
import { ApplicationController } from './application-controller';
import Logger from '../modules/logger';
import poll from '../lib/poll';
import { snakeCase } from 'lodash';

const ViewComponents = {
  heading(heading) {
    return html`<h3 class="Heading Heading--heavyPlain u-spacingBottomHectoSteep u-spacingTopHectoSteep">
      ${heading}</h3>`;
  },
  noAccounts() {
    return html`
      <div>
        <p><strong>Inga tillgängliga konton.</strong> Tyvärr kunde vi inte hitta några konton i den bank du valt.</p>
      </div>`;
  },
  accountList(accounts) {
    const accountListItems = accounts.map(ViewComponents.accountListItem).join('');

    return html`
      <ul class="GlobalParentGeneric-dividedList u-spacingBottomNone">
        ${accountListItems}
      </ul>`;
  },
  accountListItem(account) {
    return html`
      <li class="GlobalParentGeneric-dividedListItem">
        <button class="GlobalParentGeneric-dividedListItemLink"
                data-account-number="${account.account_number}"
                data-account-clearing-number="${account.clearing_number}"
                data-action="global-parent-lookup#onAccountClick"
        >
          <strong>${account.name}</strong><br>
          ${account.obfuscated_number}
        </button>
      </li>`;
  },
  backButton() {
    return html`
      <button class="u-buttonAsLink" data-action="click->global-parent-lookup#onBackClick">
        Ange manuellt istället
      </button>`;
  },
  cancelButton() {
    return html`
      <button class="Button Button--secondary" data-action="click->global-parent-lookup#onCancelClick">
        Avbryt
      </button>`;
  },
  bankIdLoader() {
    const spinner = html`
      <div class="BankIDLoader u-blockCenter u-spacingBottomDecaSteep">
        <svg preserveAspectRatio="xMinYMin meet" class="BankIDLoader-logotype" viewBox="0 0 48 46" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <title>BankID</title>
          <path
            d="M15.507 24.263l1.972-12.48h-2.106c-.986 0-2.256-.554-2.63-1.574-.12-.345-.403-1.53 1.225-2.685.583-.405.956-.855 1.031-1.2.075-.36-.015-.675-.269-.915-.358-.345-1.06-.54-1.957-.54-1.508 0-2.57.87-2.674 1.5-.074.465.284.84.598 1.08.941.705 1.165 1.725.582 2.685-.597.99-1.897 1.635-3.286 1.65H5.842c-.18 1.215-3.107 19.844-3.331 21.313h11.667c.104-.66.642-4.184 1.374-8.834h-.045z"
            fill="#479cbe" />
          <path
            d="M31.132.864H19.256L17.673 10.96h2.017c1.105 0 2.15-.51 2.599-1.245.15-.24.21-.45.21-.645 0-.42-.285-.735-.569-.945-.776-.585-.94-1.2-.94-1.635 0-.09 0-.165.014-.24.165-1.065 1.599-2.22 3.496-2.22 1.135 0 2.002.27 2.524.765.463.435.643 1.05.508 1.695-.164.765-.926 1.395-1.36 1.71-1.15.81-1 1.515-.925 1.725.239.63 1.15 1.035 1.852 1.035h3.062v.015c4.183.03 6.424 1.965 5.722 6.464-.658 4.185-3.854 5.985-7.664 6.015l-1.508 9.66h2.226c9.396 0 17.074-6.06 18.583-15.63C49.387 5.604 41.888.864 31.132.864z"
            fill="#00a5c3" />
          <path
            d="M31.132.864H19.256L17.673 10.96h2.017c1.105 0 2.15-.51 2.599-1.245.15-.24.21-.45.21-.645 0-.42-.285-.735-.569-.945-.776-.585-.94-1.2-.94-1.635 0-.09 0-.165.014-.24.165-1.065 1.599-2.22 3.496-2.22 1.135 0 2.002.27 2.524.765.463.435.643 1.05.508 1.695-.164.765-.926 1.395-1.36 1.71-1.15.81-1 1.515-.925 1.725.239.63 1.15 1.035 1.852 1.035h3.062v.015c4.183.03 6.424 1.965 5.722 6.464-.658 4.185-3.854 5.985-7.664 6.015l-1.508 9.66h2.226c9.396 0 17.074-6.06 18.583-15.63C49.387 5.604 41.888.864 31.132.864zM1.883 37.402H6.65c2.031 0 2.524 1.035 2.375 1.98-.12.765-.643 1.335-1.539 1.71 1.135.435 1.584 1.11 1.42 2.175-.21 1.335-1.36 2.325-2.869 2.325H.613l1.27-8.19zm3.152 3.39c.926 0 1.36-.495 1.45-1.08.089-.63-.195-1.065-1.121-1.065h-.822l-.328 2.145h.821zm-.508 3.54c.956 0 1.509-.39 1.643-1.185.105-.69-.283-1.095-1.21-1.095h-.94l-.36 2.295h.867v-.015zM15.582 45.653c-1.24.09-1.837-.045-2.136-.585a4.082 4.082 0 0 1-2.166.615c-1.404 0-1.897-.735-1.763-1.545.06-.39.284-.765.643-1.08.776-.675 2.688-.765 3.435-1.275.06-.57-.164-.78-.866-.78-.822 0-1.509.27-2.689 1.08l.284-1.86c1.016-.734 2.002-1.08 3.137-1.08 1.449 0 2.734.6 2.495 2.19l-.284 1.8c-.105.63-.075.825.627.84l-.717 1.68zm-2.151-2.82c-.657.42-1.882.345-2.017 1.215-.06.405.194.705.598.705.388 0 .866-.165 1.255-.435-.03-.15-.015-.3.03-.585l.134-.9zM17.897 39.249h2.48l-.134.825c.791-.675 1.389-.93 2.166-.93 1.389 0 2.031.855 1.807 2.25l-.642 4.184h-2.48l.538-3.465c.104-.63-.09-.93-.568-.93-.388 0-.747.21-1.09.675l-.568 3.705h-2.48l.971-6.314zM26.143 37.402h2.48l-.627 4.02 2.375-2.175h3.062l-3.047 2.7 2.45 3.63h-3.122l-1.883-2.925h-.03l-.448 2.925h-2.48l1.27-8.175z"
            fill="#235971" />
          <path
            d="M34.927 37.402h2.853l-1.255 8.175h-2.853l1.255-8.175zM39.169 37.402h4.078c3.152 0 4.063 2.295 3.764 4.2-.284 1.86-1.748 3.975-4.511 3.975h-4.601l1.27-8.175zm2.644 6.225c1.389 0 2.15-.69 2.375-2.145.164-1.08-.164-2.145-1.703-2.145h-.762l-.657 4.29h.747z"
            fill="#479cbe" />
        </svg>

        <div class="BankIDLoader-spinner Loader">Laddar…</div>
      </div>`;
    return html`
      <div class="FocusedContent-loader">
        <div class="PhoneHalf PhoneHalf--centerContent" style="width: 100%;">
          <svg class="PhoneHalf-illustration" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 256 235">
            <path fill="#d6dee2"
                  d="M227.5 233.7V27.4c0-15.1-12.2-27.4-27.3-27.4H55.8C40.7 0 28.5 12.3 28.5 27.4v206.3H0v1.3h256v-1.3h-28.5zM40.4 58h175.3v175.7H40.4V58z" />
          </svg>

          <div class="PhoneHalf-content">
            ${spinner}
          </div>
        </div>
      </div>`;
  },
  // openBankIdButton(href) {
  //   return html`<a class="Button" href="${href}">
  //     Öppna på den här enheten
  //   </a>`;
  // },
  openBankIdButton() {
    return html`
      <button class="Button" data-action="click->global-parent-lookup#onOpenBankIdApp">
        Öppna på den här enheten
      </button>`;
  },
  bankListItem({ name, id }) {
    return html`
      <li class="GlobalParentGeneric-dividedListItem">
        <button class="GlobalParentGeneric-dividedListItemLink"
                data-bank-id="${id}"
                data-action="global-parent-lookup#onBankClick"
        >${name}
        </button>
      </li>`;
  },
  otherBankListItem() {
    return html`
      <li class="GlobalParentGeneric-dividedListItem">
        <button class="GlobalParentGeneric-dividedListItemLink"
                data-action="global-parent-lookup#onOtherBankClick"
        >Annan Bank
        </button>
      </li>`;
  },
};

const Views = {
  error(controller) {
    return html`
      ${ViewComponents.heading('Något gick fel')}
      <p>
        Tyvärr har vi inte möjlighet att hämta ditt kontonummer.
        Vi hoppas att du vill ange kontonumret manuellt istället.
      </p>
      <p><a class="Button Button--blue Button--s" href="${controller.backPathValue}">Ange kontonummer manuellt</a></p>
      </div>`;
  },
  otherBank(controller) {
    return html`
      <div>
        ${ViewComponents.heading('Saknas din bank i listan?')}
        <p>
          Tyvärr har vi inte möjlighet att hämta kontonummer från vissa banker.
          Om din bank inte finns med i listan hoppas vi att du istället kan ange kontonumret manuellt.
        </p>
        <p><a class="Button Button--blue Button--s" href="${controller.backPathValue}?manual=true">Ange kontonummer
          manuellt</a></p>
      </div>`;
  },
  accountList(controller) {
    const { accounts = [] } = controller.state.lookup;

    const accountList = accounts.length
      ? ViewComponents.accountList(accounts)
      : ViewComponents.noAccounts();

    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading('Välj Konto')}
        </div>
        <div class="FocusedContent-content">
          ${accountList}
        </div>

        <div class="FocusedContent-actions">
          <button class="u-buttonAsLink" data-action="click->global-parent-lookup#onBackClick">
            Ange manuellt istället
          </button>
        </div>
      </div>
    `;
  },
  bankList(controller) {
    if (!controller.hasBanksValue || controller.banksValue.length <= 0) {
      throw new Error('Banks not loaded');
    }

    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading('Välj Bank')}
        </div>
        <div class="FocusedContent-content">
          <ul class="GlobalParentGeneric-dividedList u-spacingBottomNone">
            ${controller.banksValue.map(ViewComponents.bankListItem).join('')}
            ${ViewComponents.otherBankListItem()}
          </ul>
        </div>

        <div class="FocusedContent-actions">
          <button class="u-buttonAsLink" data-action="click->global-parent-lookup#onBackClick">
            Ange manuellt istället
          </button>
        </div>
      </div>
    `;
  },
  openSameDevice(controller) {
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading(controller.state.selectedBank.name)}
        </div>
        <div class="FocusedContent-content">
          ${ViewComponents.bankIdLoader()}
          ${ViewComponents.openBankIdButton()}
        </div>
        <div class="FocusedContent-text">
          <p>Öppna BankID-appen på din mobil eller surfplatta och följ anvisningarna.</p>
        </div>
        <p class="FocusedContent-info-text">Efter att du signerat med Bank-ID kan det ta upp till 15 sekunder innan
          sidan laddas om. Tack för ditt tålamod.</p>
        <div class="FocusedContent-actions">
          ${ViewComponents.backButton()}
        </div>
      </div>
    `;
  },
  loaderContactingBank(controller) {
    const helpText = controller.state.isMobile
      ? html`
        <p>Kontaktar din bank.</p>
      ` : html`
        <p>Kontaktar din bank.</p>
        <p>Öppna BankID-appen på din mobil eller surfplatta och följ anvisningarna.</p>
      `;
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading(controller.state.selectedBank.name || 'Välj bank')}
        </div>
        <div class="FocusedContent-content">
          ${ViewComponents.bankIdLoader()}
        </div>
        <div class="FocusedContent-text">
          ${helpText}
        </div>
        <div class="FocusedContent-actions">
          ${ViewComponents.backButton()}
        </div>
      </div>
    `;
  },
  loaderSelectedAccount() {
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading('Välj konto')}
        </div>
        <div class="FocusedContent-content">
          ${ViewComponents.bankIdLoader()}
        </div>
        <div class="FocusedContent-actions">
          ${ViewComponents.backButton()}
        </div>
      </div>
    `;
  },
  loaderCancel() {
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading('Avbryter...')}
        </div>
        <div class="FocusedContent-content">
          ${ViewComponents.bankIdLoader()}
        </div>
        <div class="FocusedContent-actions">
          ${ViewComponents.backButton()}
        </div>
      </div>
    `;
  },
  loaderWithQrCode(controller) {
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading(controller.state.selectedBank.name)}
        </div>
        <div class="FocusedContent-content">
          <div>
            <div class="u-spacingBottomDecaSteep FocusedContent-qrCode">
              <img class="FocusedContent-qrCodeImage" alt="QR-kod" src="${controller.state.lookup.qr_code}" />
            </div>
          </div>
          ${ViewComponents.cancelButton()}
        </div>
        <div class="FocusedContent-text">
          <div class="u-displayFlex u-flexItemsCenter u-flexContentCenter">
            <div class="Loader Loader--small Loader--inline"></div>
            <p class="u-displayInline">Skanna QR-koden i din BankID-app.</p>
          </div>
        </div>
        <div class="FocusedContent-actions">
          ${ViewComponents.backButton()}
        </div>
      </div>
    `;
  },
  loaderCancelSession() {
    return html`
      <div class="FocusedContent FocusedContent--no-padding">
        <div class="FocusedContent-heading">
          ${ViewComponents.heading('Du har en pågående inloggningssession')}
        </div>
        <div class="FocusedContent-content">
          ${ViewComponents.bankIdLoader()}
        </div>
        <div class="FocusedContent-text">
          <div class="u-displayFlex u-flexItemsCenter u-flexContentCenter">
            <p class="u-displayInline">Vänligen öppna BankID appen och avbryt inloggningen, eller vänta några minuter
              och försök igen. </p>
          </div>
        </div>
      </div>
    `;
  },
};

const LOOKUP_FOUND = 'found';
const LOOKUP_NOT_FOUND = 'not_found';
const LOOKUP_NOT_ALLOWED = 'not_allowed';

// Stimulus controller used for bank account lookups in the Global Parent Signup flow

class GlobalParentLookupController extends ApplicationController {
  static targets = [
    'bankAccountClearingInput',
    'bankAccountInput',
    'view',
  ];

  static values = {
    civicNumber: String,
    apiBase: String,
    backPath: String,
    signatureUrl: String,
    banks: Array,
    pollInterval: Number,
    cancelationPollInterval: Number,
    pollTimeout: Number,
    requestTimeout: Number,
    class: String,
  };

  initialize() {
    this.axios = null;

    this.render = this.render.bind(this);

    this.cancelAccountPoll = () => new Error('Poll not started');

    this.state = {
      lookup: null,
      isMobile: null,
      isAndroid: null,
      selectedBank: null,
      sessionId: null,
      selectedAccountClicked: false,
    };
  }

  connect() {
    this.state.isMobile = !!this.matchesUserAgent('Android|iPhone');
    this.state.isAndroid = !!this.matchesUserAgent('Android');

    // Set up clients
    this.axios = axios.create({
      baseURL: this.apiBaseValue,
      timeout: this.requestTimeoutValue,
      headers: { Accept: 'application/json' },
    });

    this.axios.interceptors.request.use((req) => {
      req.meta = req.meta || {};
      req.meta.requestStartedAt = new Date().getTime();
      return req;
    });

    this.axios.interceptors.response.use((res) => {
      const elapsedtime = new Date().getTime() - res.config.meta.requestStartedAt;
      if (elapsedtime > 1000) Logger.warning('Request took more than 1 second', {}, { round_trip_time: elapsedtime });
      return res;
    });

    this.getExistingReq()
      .then((response) => {
        switch (response.data.result) {
          case LOOKUP_NOT_FOUND:
            return this.render(Views.bankList(this));
          case LOOKUP_NOT_ALLOWED:
            return this.pollForCancelation();
          case LOOKUP_FOUND:
            this.state.sessionId = response.data.session;
            return this.pollForAccounts();
          default:
            throw new Error('Unexpected response');
        }
      })
      .catch((err) => {
        this.render(Views.error(this));
        this.handleError(err);
      });
  }

  // Method - matchesUserAgent: checks the userAgent
  matchesUserAgent(pattern) {
    const re = new RegExp(pattern, 'i');
    return navigator.userAgent.match(re);
  }

  // Method - render: renders to viewTarget
  render(htmlContent) {
    if (!this.hasViewTarget) {
      throw new Error('Missing view target');
    }

    this.viewTarget.innerHTML = htmlContent;
  }

  // Request - createLookup: Creates a lookup for a given bank and civic number
  createLookupReq() {
    return this.axios.post('', JSON.stringify({
        bank_accounts_lookup: {
          bank_id_same_device: this.state.isMobile,
          civic_number: this.civicNumberValue,
          bank: this.state.selectedBank.id,
        },
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
  }

  async pollForAccounts() {
    const [pollPromise, cancelFunc] = poll({
      url: `${this.apiBaseValue}/${this.state.sessionId}`,
      responseType: 'text',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      compareFn: (response, data) => {
        const state = response.headers.get('X-Lookup-State');
        if (data !== this.lastPollContent) {
          this.onPollResponse(state, data);
        }

        this.lastPollContent = data;
        return state !== 'pending';
      },
      timeout: this.pollTimeoutValue,
      interval: this.pollIntervalValue,
    });

    this.cancelAccountPoll = cancelFunc;
    return pollPromise;
  }

  async getExistingReq() {
    return this.axios.get('/existing', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      params: {
        civic_number: this.civicNumberValue,
      },
    });
  }

  async pollForCancelation() {
    const [pollPromise] = poll({
      url: `${this.apiBaseValue}/existing?civic_number=${this.civicNumberValue}`,
      responseType: 'text',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      compareFn: (response, json) => {
        const data = JSON.parse(json);
        if (data.result === LOOKUP_NOT_FOUND) {
          this.render(Views.bankList(this));
          return true;
        }
        if (data.result === LOOKUP_FOUND || data.result === LOOKUP_NOT_ALLOWED) {
          this.state.sessionId = data.session;
          this.render(Views.loaderCancelSession(this));
        } else {
          this.render(Views.error(this));
        }

        return false;
      },
      timeout: this.pollTimeoutValue,
      interval: this.cancelationPollIntervalValue,
    });

    return pollPromise.catch((err) => {
      this.render(Views.error(this));
      this.handleError(err);
    });
  }

  getPendingView() {
    const qrCode = this.state.lookup.qr_code;
    const autoStartToken = this.state.lookup.auto_start_token;

    if (autoStartToken && this.state.isMobile) {
      return Views.openSameDevice(this);
    }
    if (qrCode) {
      return Views.loaderWithQrCode(this);
    }

    return Views.loaderContactingBank(this);
  }

  // Event handler - onPollResponse: Renders correct view depending on state
  onPollResponse(state, json) {
    const data = JSON.parse(json);
    this.state.lookup = data.lookup;
    const bank = this.banksValue.find(b => b.id === this.state.lookup.bank);
    this.state.selectedBank = bank;
    switch (state) {
      case 'pending':
        this.render(this.getPendingView(this));
        break;
      case 'completed':
        this.render(Views.accountList(this));
        break;
      case 'failed':
      case 'errored':
        this.render(Views.error(this));
        break;
      default:
    }
  }

  // TODO: mby remove
  onOpenBankIdApp() {
    const queryParams = [];
    let returnUrl = 'null'; // Intentionally a string

    if (this.state.lookup.auto_start_token) {
      queryParams.push(`autostarttoken=${this.state.lookup.auto_start_token}`);
    }

    // "#" is there to prevent browser from reloading the page when returning
    returnUrl = this.state.isAndroid
      ? 'null'
      : window.encodeURIComponent(`${window.location.href}#`);
    queryParams.push(`redirect=${returnUrl}`);


    const url = this.state.isMobile
      ? `https://app.bankid.com/?${queryParams.join('&')}`
      : `bankid:///?${queryParams.join('&')}`;

    window.location.replace(url);
  }

  // Event handler - onOtherBankClick: shows back message
  onOtherBankClick() {
    this.render(Views.otherBank(this));
  }

  // Event handler - onBankClick: Handles bank selection
  onBankClick(e) {
    const bank = this.banksValue.find(b => b.id === e.currentTarget.dataset.bankId);
    this.state.selectedBank = bank;
    this.render(Views.loaderContactingBank(this));

    this.createLookupReq()
      .then((res) => {
        this.state.sessionId = res.data.session;
        this.pollForAccounts();
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) { // This shouldn't happen
          Logger.info(`Lookup Creation failed with status 409: ${err.message}`);
          this.state.sessionId = err.response.data.session;
          this.pollForAccounts();
        } else {
          this.render(Views.error(this));
          this.handleError(err);
        }
      });
  }

  // Event handler - accountClick: Handles account selection
  onAccountClick(e) {
    e.preventDefault();
    if (this.state.selectedAccountClicked) return;
    this.state.selectedAccountClicked = true;
    const { accountClearingNumber } = e.currentTarget.dataset;
    const { accountNumber } = e.currentTarget.dataset;
    const formData = new FormData();

    const resourceType = snakeCase(this.classValue);

    formData.append(`${resourceType}[state]`, 'processing_digital_signature');
    formData.append(`${resourceType}[bank_account_clearing_part]`, accountClearingNumber);
    formData.append(`${resourceType}[bank_account_serial_part]`, accountNumber);
    formData.append(`${resourceType}[with_bank_account_lookup]`, true);
    this.axios({
      url: this.signatureUrlValue,
      method: 'put',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      this.state.selectedAccountClicked = false;
      window.location = res.data.url;
    }).catch((err) => {
      this.state.selectedAccountClicked = false;
      this.render(Views.error(this));
      this.handleError(err);
    });
  }

  // Event handler - onCancelClick: Handles account selection
  onCancelClick() {
    this.cancelAccountPoll();
    this.pollForCancelation();
  }

  // Event handler - onCancelClick: Handles account selection
  onBackClick() {
    this.render(Views.loaderCancel(this));
    window.location = this.backPathValue;
  }
}

export {
  GlobalParentLookupController,
  GlobalParentLookupController as default,
};
