import { Controller } from 'stimulus';

import { isVisibleInViewport } from '../modules/viewport-visibility';
import { push as gtmPush } from '../modules/gtm';

export default class extends Controller {
  static values = {
    virtualGift: Object,
    isPushed: Boolean,
    list: String,
    position: Number,
  };

  connect() {
    this.push();
  }

  push() {
    if (this.isPushedValue || !isVisibleInViewport(this.element)) return;

    const event = {
      event: 'EECproductImpression',
      ecommerce: {
        currencyCode: 'SEK',
        impressions: [
          {
            name: this.virtualGiftValue.name || undefined,
            id: this.virtualGiftValue.id || undefined,
            price: this.virtualGiftValue.price || undefined,
            brand: 'Unicef',
            category: 'Gifts',
            list: this.listValue || undefined,
            position: this.positionValue || undefined,
          },
        ],
      },
    };

    gtmPush(event);
    this.isPushedValue = true;
  }
}
