/* eslint-disable import/no-mutable-exports */

import BackboneEvents from 'backbone-events-standalone';

let EventBuffer = {};

BackboneEvents.mixin(EventBuffer);

// If window.UnicefEvents (legacy) is available,
// use that to maintain interoperability with the old JS codebase
EventBuffer = window.UnicefEvents || EventBuffer;

export {
  EventBuffer,
  EventBuffer as default,
};
