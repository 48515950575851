/*
 * Cart Item Controller
 *
 * Responsible for handling interactions for a single line in
 * the site head cart, and loading new cart contents after the cart has been
 * updated, with a certain delay.
 */

import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

import { EventBuffer } from '../../lib/event-buffer';
import { fetchCart } from '../../modules/cart-helpers';

import {
  ANALYTICS_CHANGE_EVENT_NAME,
  CHANGE_DEBOUNCE_DELAY,
  UPDATE_START_EVENT_NAME,
  UPDATE_DONE_EVENT_NAME,
  LOADING_CLASS_NAME,
} from '../../constants/cart';

class CartItemController extends Controller {
  static targets = ['form', 'quantity'];

  initialize() {
    this.onQuantityChanged = debounce(this.onQuantityChanged, CHANGE_DEBOUNCE_DELAY);
    this.onUpdateDone = this.onUpdateDone.bind(this);
    this.onUpdateStart = this.onUpdateStart.bind(this);
  }

  connect() {
    EventBuffer.on(UPDATE_DONE_EVENT_NAME, this.onUpdateDone);
    EventBuffer.on(UPDATE_START_EVENT_NAME, this.onUpdateStart);
  }

  disconnect() {
    EventBuffer.off(UPDATE_DONE_EVENT_NAME, this.onUpdateDone);
    EventBuffer.off(UPDATE_START_EVENT_NAME, this.onUpdateStart);
  }

  quantityChangeAction() {
    this.updateQuantity(this.quantity || 1);
  }

  incrementAction(e) {
    if (e) e.preventDefault();

    this.updateQuantity(this.quantity + 1);
  }

  decrementAction(e) {
    if (e) e.preventDefault();

    this.updateQuantity(this.quantity - 1);
  }

  removeAction(e) {
    if (e) e.preventDefault();

    this.onRemoved();
  }

  updateQuantity(quantity) {
    if (quantity < 1) {
      return;
    }

    this.quantity = quantity;

    this.onQuantityChanged();
  }

  onQuantityChanged() {
    this.sendRequest(this.formData);

    EventBuffer.trigger(ANALYTICS_CHANGE_EVENT_NAME, {
      id: this.id,
      name: this.name,
      price: this.price,
      quantityChange: this.quantity - this.originalQuantity,
    });
  }

  onRemoved() {
    const formData = new FormData();

    formData.append('authenticity_token', this.authenticity_token);
    formData.append('_method', 'delete');

    EventBuffer.trigger(ANALYTICS_CHANGE_EVENT_NAME, {
      id: this.id,
      name: this.name,
      price: this.price,
      quantityChange: this.originalQuantity * -1,
    });

    this.sendRequest(formData);
  }

  sendRequest(body) {
    fetchCart({
      url: this.url,
      body,
    });
  }

  onUpdateStart() {
    if (this.hasFormTarget) {
      this.formTarget.classList.add(LOADING_CLASS_NAME);
    }
  }

  onUpdateDone() {
    if (this.hasFormTarget) {
      this.formTarget.classList.remove(LOADING_CLASS_NAME);
    }
  }

  get id() {
    return this.data.get('virtual-gift-id');
  }

  get name() {
    return this.data.get('name');
  }

  get price() {
    return parseInt(this.data.get('price'), 10);
  }

  get originalQuantity() {
    return parseInt(this.data.get('current-quantity'), 10);
  }

  get quantity() {
    return parseInt(this.quantityTarget.value || 0, 10);
  }

  set quantity(value) {
    delete this.cachedFormData;

    this.quantityTarget.value = value;
  }

  get url() {
    return this.formTarget.getAttribute('action');
  }

  get formData() {
    if (!this.cachedFormData) {
      this.cachedFormData = new FormData(this.formTarget);
    }

    return this.cachedFormData;
  }

  get authenticityToken() {
    return this.formData.get('authenticity_token');
  }
}

export {
  CartItemController,
  CartItemController as default,
};
