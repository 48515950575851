import { Controller } from 'stimulus';

import trackEvent from '../modules/track-event';

const OPTION_LIST_INITIAL_CHECKED_EVENT_NAME = 'option-list-initial-checked';
const OPTION_LIST_CHANGE_EVENT_NAME = 'option-list-change';
const TRACK_CALLBACK_TIMEOUT = 2500; // ms

class TrackFormOptionList extends Controller {
  connect() {
    this.type = this.data.get('eventType');
    this.submitted = false;
    this.gotCallback = false;
    this.onInitialChecked = this.onInitialChecked.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.initialCheckedOptionLabel = null;
    this.checkedOptionLabel = '';
    this.changedOption = false;

    window.addEventListener(OPTION_LIST_INITIAL_CHECKED_EVENT_NAME, this.onInitialChecked, false);
    window.addEventListener(OPTION_LIST_CHANGE_EVENT_NAME, this.onOptionChange, false);
  }

  onSubmit(event) {
    if (!this.submitted) {
      const properties = {
        changedOption: this.changedOption,
      };

      event.preventDefault();

      if (this.initialCheckedOptionLabel) {
        Object.assign(properties, {
          usedInitiallyCheckedOption: this.initialCheckedOptionLabel === this.checkedOptionLabel,
        });
      }

      trackEvent(this.type, properties, () => {
        this.gotCallback = true;
        this.element.submit();
      });

      // It should be possible to submit form even if track serivce has problems.
      window.setTimeout(() => {
        if (!this.gotCallback) {
          this.element.submit();
        }
      }, TRACK_CALLBACK_TIMEOUT);

      this.submitted = true;
    }
  }

  onInitialChecked(event) {
    this.initialCheckedOptionLabel = event.detail.label;
    this.checkedOptionLabel = event.detail.label;
  }

  onOptionChange(event) {
    this.changedOption = true;
    this.checkedOptionLabel = event.detail.label;
  }
}

export {
  TrackFormOptionList,
  TrackFormOptionList as default,
};
