import { Controller } from 'stimulus';

import Modal from '../modules/modal';

const API_ENDPOINT = '/donors.json';
const HIDDEN_CLASS_NAME = 'is-hidden';
const FB_REQUIRED_PERMISSIONS = ['public_profile'];
const FORM_TEMPLATE_SELECTOR = '.js-new-donor-modal-form-template';
const FORM_SELECTOR = '.js-new-donor-modal-form';
const CONSENT_ERROR_SELECTOR = '.js-new-donor-modal-consent-error';
const CREATE_COMPLETE_EVENT_NAME = 'new-donor-create-complete';

function getMissingPermissions(grantedPermissions) {
  return FB_REQUIRED_PERMISSIONS.filter(permission => !grantedPermissions.includes(permission));
}

class NewDonorController extends Controller {
  static targets = ['text'];

  initialize() {
    this.modalFormTemplate = document.querySelector(FORM_TEMPLATE_SELECTOR).innerHTML;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onCreateCompleteEvent = this.onCreateCompleteEvent.bind(this);
  }

  connect() {
    this.modal = null;
    this.shareUrl = this.data.get('share-url');
    this.doneText = this.data.get('done-text');

    window.addEventListener(CREATE_COMPLETE_EVENT_NAME, this.onCreateCompleteEvent, false);
  }

  create() {
    window.FB.login((response) => {
      if (response.authResponse) {
        const grantedPermissions = (response.authResponse.grantedScopes || '').split(',');
        const missingPermissions = getMissingPermissions(grantedPermissions);

        if (missingPermissions.length) {
          console.log('Missing permissions', missingPermissions); // eslint-disable-line no-console
        } else {
          this.showModal();
        }
      } else {
        console.log('User not logged into this app or we are unable to tell.'); // eslint-disable-line no-console
      }
    }, {
      scope: FB_REQUIRED_PERMISSIONS.join(','),
      return_scopes: true,
    });
  }

  onFormSubmit(event) {
    const formData = new FormData(event.target);

    event.preventDefault();

    if (formData.get('consent')) {
      this.createDonor(formData);
    } else {
      this.showConsentError();
    }
  }

  setupFormEventListener() {
    document.querySelector(FORM_SELECTOR).addEventListener('submit', this.onFormSubmit);
  }

  showModal() {
    this.modal = new Modal(this.modalFormTemplate);

    this.modal.show();
    this.setupFormEventListener();
  }

  createDonor(body) {
    const message = body.get('message');

    window.FB.api('/me', async (response) => {
      if (response && !response.error) {
        body.append('donor[facebook_id]', response.id);
        body.append('donor[message]', message);

        const resp = await fetch(API_ENDPOINT, {
          body,
          method: 'POST',
          credentials: 'include',
        });

        if (resp.ok) {
          const data = await resp.json();

          this.onCreateComplete(data);
        } else {
          const error = new Error(`request failed with status ${resp.status}: ${resp.statusText}`);
          throw error;
        }
      }
    });
  }

  onCreateCompleteEvent() {
    this.element.setAttribute('disabled', 'disabled');

    if (this.hasTextTarget && this.doneText) {
      this.textTarget.innerHTML = this.doneText;
    }
  }

  onCreateComplete(donor) {
    const createEvent = new CustomEvent(CREATE_COMPLETE_EVENT_NAME, {
      detail: {
        donor: Object.assign({}, donor, { new: true }),
      },
    });

    window.dispatchEvent(createEvent);

    this.modal.hide();
    this.displayFBShareDialog(donor.message);
  }

  displayFBShareDialog(message) {
    window.FB.ui({
      href: this.shareUrl,
      quote: message,
      method: 'share',
    });
  }

  showConsentError() {
    document.querySelector(CONSENT_ERROR_SELECTOR).classList.remove(HIDDEN_CLASS_NAME);
  }
}

export {
  NewDonorController,
  NewDonorController as default,
};
