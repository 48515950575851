import { Controller } from 'stimulus';

const IS_POINTER_STATE = 'is-pointer';
const TAB_KEY_CODE = 9;

class IsPointerController extends Controller {
  connect() {
    this.isPointer = false;
    this.addPointerState = this.addPointerState.bind(this);
    this.removePointerState = this.removePointerState.bind(this);

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.element.addEventListener('mousedown', this.addPointerState, false);
    this.element.addEventListener('keydown', this.removePointerState, false);
  }

  addPointerState() {
    if (!this.isPointer) {
      this.element.classList.add(IS_POINTER_STATE);
      this.isPointer = true;
    }
  }

  removePointerState(event) {
    const isTab = event.keyCode === TAB_KEY_CODE;

    if (isTab && this.isPointer) {
      this.element.classList.remove(IS_POINTER_STATE);
      this.isPointer = false;
    }
  }
}

export {
  IsPointerController,
  IsPointerController as default,
};
