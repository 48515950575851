import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['trigger', 'content'];

  connect() {
    this.triggerTargets.forEach(trigger => {
      this.toggle(trigger);

      trigger.addEventListener('change', (e) => this.toggle(e.target));
    });
  }

  toggle(trigger) {
    const triggerType = trigger.dataset.togglerType;
    const triggerValue = trigger.dataset.togglerValue;

    const contentsOfType = this.contentTargets.filter(content =>
      content.dataset.togglerType === triggerType
    );

    contentsOfType.forEach(content => {
      this.toggleElement(content, trigger.checked, content.dataset.togglerValue === triggerValue);
    });
  }

  hideElement(element) {
    element.classList.add('u-hide');
    [...element.getElementsByTagName('input')].forEach(input => input.disabled = true);
  }

  showElement(element) {
    element.classList.remove('u-hide');
    [...element.getElementsByTagName('input')].forEach(input => input.disabled = false);
  }

  toggleElement(element, isChecked, isValueMatching) {
    if ((isValueMatching && isChecked) || (!isValueMatching && !isChecked)) {
      this.showElement(element);
    } else if ((isValueMatching && !isChecked) || (!isValueMatching && isChecked)) {
      this.hideElement(element);
    }
  }
}
