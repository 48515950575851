import { Controller } from 'stimulus';
import fitty from 'fitty';

const FITTY_OPTIONS = {
  minSize: 12,
};

class FitTextController extends Controller {
  initialize() {
    this.render = this.render.bind(this);
    this.render();
  }

  connect() {
    window.addEventListener('load', this.render, false);
  }

  disconnect() {
    window.removeEventListener('load', this.render, false);
  }

  render() {
    fitty(this.element, FITTY_OPTIONS);
  }
}

export {
  FitTextController,
  FitTextController as default,
};
