import { Controller } from 'stimulus';
import Packery from 'packery';
import imagesLoaded from 'imagesloaded';

import { collageDonorsTemplate } from '../templates/collage';

const API_ENDPOINT = '/donors.json';
const HIDDEN_CLASS_NAME = 'is-hidden';
const NEW_DONOR_CREATE_COMPLETE_EVENT_NAME = 'new-donor-create-complete';
const PACKERY_OPTIONS = {
  itemSelector: '.js-pack-item',
  stamp: '.js-pack-stamp',
  percentPosition: true,
  transitionDuration: 0,
};

function queryStringFromObj(obj) {
  return Object.keys(obj).map(k => `${k}=${obj[k]}`).join('&');
}

class DonorsController extends Controller {
  static targets = ['list', 'listInner', 'loadMore'];

  initialize() {
    this.layoutPackeries = this.layoutPackeries.bind(this);
    this.onNewDonor = this.onNewDonor.bind(this);
  }

  connect() {
    this.totalPages = null;
    this.donors = [];
    this.currentPage = 1;
    this.perPage = parseInt(this.data.get('per-page'), 10);
    this.donorContext = this.data.get('context'); // 'context' is a reserved Stimulus property

    this.fetchAndRender();

    window.addEventListener(NEW_DONOR_CREATE_COMPLETE_EVENT_NAME, this.onNewDonor, false);
  }

  onNewDonor(event) {
    this.donors.unshift(event.detail.donor);
    this.render();
  }

  async fetchAndRender() {
    const queryString = queryStringFromObj({
      context: this.donorContext,
      per_page: this.perPage,
      page: this.currentPage,
    });
    const url = `${API_ENDPOINT}?${queryString}`;
    const resp = await fetch(url);

    if (!resp.ok) {
      const error = new Error(`request failed with status ${resp.status}: ${resp.statusText}`);
      throw error;
    }

    const data = await resp.json();

    this.donors.push(...data.donors);
    this.totalPages = data.total_pages;
    this.render();
  }

  loadMore() {
    this.currentPage += 1;
    this.fetchAndRender();
  }

  hideLoadMore() {
    this.loadMoreTarget.classList.add(HIDDEN_CLASS_NAME);
  }

  render() {
    if (this.currentPage >= this.totalPages) {
      this.hideLoadMore();
    }

    this.element.classList.remove(HIDDEN_CLASS_NAME);
    this.listTarget.innerHTML = collageDonorsTemplate(this.donors);

    this.layoutPackeries();
    this.layoutPackeriesAfterImageLoad();
  }

  layoutPackeries() {
    new Packery(this.listInnerTarget, PACKERY_OPTIONS); // eslint-disable-line no-new
  }

  layoutPackeriesAfterImageLoad() {
    imagesLoaded(this.listTarget).off('progress', this.layoutPackeries);
    imagesLoaded(this.listTarget).on('progress', this.layoutPackeries);
  }
}

export {
  DonorsController,
  DonorsController as default,
};
