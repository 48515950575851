/* keypress event keyCode map
backspace   8
tab         9
shift       16
ctrl        17
alt         18
pause/break 19
caps lock   20
escape      27
page up     33
page down   34
end         35
home        36
left arrow  37
up arrow    38
right arrow 39
down arrow  40
insert      45
delete      46
*/

import { Controller } from 'stimulus';

const ALLOWED_KEY_CODES = [8, 9, 16, 17, 18, 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46];
const ENTER_KEY_CODE = 13;
const WARN_CHARS_REMAINING = 10;
const WARN_CLASS_NAME = 'u-colorNegative';

class LimitInputLengthController extends Controller {
  static targets = ['input', 'remainingChars'];

  connect() {
    this.maxChars = parseInt(this.data.get('max-chars'), 10);
    this.maxRows = parseInt(this.data.get('max-rows'), 10) || Infinity;

    this.onInput = this.onInput.bind(this);

    this.setupEventListeners();
    this.updateRemainingChars();
  }

  setupEventListeners() {
    this.inputTarget.addEventListener('keydown', this.onInput);
    this.inputTarget.addEventListener('keyup', this.onInput);
    this.inputTarget.addEventListener('change', this.onInput);
  }

  onInput(event) {
    const charCount = this.inputTarget.value.length;
    const rowCount = this.inputTarget.value.split(/\n/g).length;

    // Limit number of characters
    if (!(ALLOWED_KEY_CODES.includes(event.keyCode) || event.metaKey)) {
      if (charCount >= this.maxChars) {
        this.inputTarget.value = this.inputTarget.value.substring(0, this.maxChars);
        event.preventDefault();
      }
    }

    // Limit number of rows
    if (this.maxRows && event.keyCode === ENTER_KEY_CODE) {
      if (rowCount >= this.maxRows) {
        event.preventDefault();
      }
    }

    this.updateRemainingChars();
  }

  updateRemainingChars() {
    const charCount = this.inputTarget.value.length;
    const remainingChars = this.maxChars - charCount;

    this.remainingCharsTarget.innerHTML = `${remainingChars} tecken kvar.`;

    if (remainingChars <= WARN_CHARS_REMAINING && !this.remainingCharsTarget.classList.contains(WARN_CLASS_NAME)) {
      this.remainingCharsTarget.classList.add(WARN_CLASS_NAME);
    } else if (remainingChars > WARN_CHARS_REMAINING && this.remainingCharsTarget.classList.contains(WARN_CLASS_NAME)) {
      this.remainingCharsTarget.classList.remove(WARN_CLASS_NAME);
    }
  }
}

export {
  LimitInputLengthController,
  LimitInputLengthController as default,
};
