import { Controller } from 'stimulus';

const SCROLL_PADDING = 10;

class ScrollableContainerController extends Controller {
  static targets = ['inner']

  connect() {
    this.setScrollState();
  }

  setScrollState() {
    const { scrollWidth, scrollLeft } = this.innerTarget;
    const innerWidth = Math.round(this.innerTarget.getBoundingClientRect().width);

    let atEndLeft = true;
    let atEndRight = false;

    if (scrollLeft > SCROLL_PADDING) {
      atEndLeft = false;
    }

    if (scrollLeft > (scrollWidth - innerWidth - SCROLL_PADDING)) {
      atEndRight = true;
    }

    if (atEndLeft) {
      this.element.classList.add('is-end-left');
    } else {
      this.element.classList.remove('is-end-left');
    }

    if (atEndRight) {
      this.element.classList.add('is-end-right');
    } else {
      this.element.classList.remove('is-end-right');
    }
  }

  onScroll() {
    this.setScrollState();
  }
}

export {
  ScrollableContainerController,
  ScrollableContainerController as default,
};
