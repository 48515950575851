export default function fetchWithDefaults(input, init = {}) {
  const { headers, ...initWithoutHeaders } = init;

  return fetch(input, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...headers,
    },
    credentials: 'same-origin',
    ...initWithoutHeaders,
  });
}
