import { features } from './feature-toggles';

export const push = (obj) => {
  if (features.disabled('gtm')) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export default push;
