import strings from '../strings/bank-id.json';

import {
  BANK_ID_FAILED_STATE,
  BANK_ID_PENDING_STATE,
  BANK_ID_ENTER_PENDING_STATE_ACTION,
  BANK_ID_HANDLE_RESPONSE_ACTION,
  BANK_ID_ERROR_ACTION,
} from '../constants/bank-id';

const DEFAULT_STATE = {
  signatureState: null,
  autoStartToken: null,
  statusHintCode: null,
  errorHintCode: null,
  statusMessage: null,
  errorMessage: null,
  returnUrl: null,
};

function getStateFromResponse(state = DEFAULT_STATE, response = {}, signatureStateOverride) {
  const newState = {
    ...state,
    signatureState: signatureStateOverride || response.state || state.signatureState,
    autoStartToken: response.auto_start_token || state.autoStartToken,
    statusHintCode: response.bank_id_hint_code || state.statusHintCode,
    errorHintCode: response.failure || state.errorHintCode,
    returnUrl: response.completion_return_url || state.returnUrl,
  };

  newState.statusMessage = strings.grandIdHints[newState.statusHintCode] || state.statusMessage;
  newState.errorMessage = strings.grandIdHints[newState.errorHintCode] || state.errorMessage;

  return newState;
}

export default function bankIdReducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case BANK_ID_ENTER_PENDING_STATE_ACTION:
      return {
        ...state,
        signatureState: BANK_ID_PENDING_STATE,
      };
    case BANK_ID_HANDLE_RESPONSE_ACTION:
      return getStateFromResponse(state, action.payload.response || {});
    case BANK_ID_ERROR_ACTION:
      return getStateFromResponse(state, action.payload.response || {}, BANK_ID_FAILED_STATE);
    default:
      return state;
  }
}
