import {
  TOGGLE_TARGET_ACTION,
} from '../constants/toggle-content';

const DEFAULT_STATE = {
  target: null,
  behavior: null,
};

export default function toggleContentReducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case TOGGLE_TARGET_ACTION:
      return {
        ...state,
        target: action.payload.target === state.target ? null : action.payload.target,
        behavior: action.payload.behavior || null,
      };
    default:
      return state;
  }
}
