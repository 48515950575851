/*
 * Cart Controller
 *
 * Responsible for updating the contents of the main site head cart element.
 *
 * - Listens to the 'toggle-content' EventBuffer event, and loads the initial
 *   cart contents if the cart is opened for the first time.
 *
 * - Listens to various 'cart:*' events and inserts updated HTML + sets
 *   loading state class names.
 */

import { Controller } from 'stimulus';

import { EventBuffer } from '../lib/event-buffer';

import { fetchCart } from '../modules/cart-helpers';

import { EVENT_NAME as TOGGLE_CONTENT_EVENT_NAME } from '../constants/toggle-content';

import {
  TOGGLE_CONTENT_TARGET,
  UPDATE_START_EVENT_NAME,
  UPDATE_DONE_EVENT_NAME,
  LOADING_CLASS_NAME,
  NEW_HTML_EVENT_NAME,
} from '../constants/cart';

class CartController extends Controller {
  static targets = ['inner'];

  didLoadHtml = false;

  initialize() {
    this.onNewHtml = this.onNewHtml.bind(this);
    this.onToggleContentToggle = this.onToggleContentToggle.bind(this);
    this.onUpdateDone = this.onUpdateDone.bind(this);
    this.onUpdateStart = this.onUpdateStart.bind(this);
  }

  connect() {
    this.connectDisconnectEventBuffer('on');
  }

  disconnect() {
    this.connectDisconnectEventBuffer('off');
  }

  connectDisconnectEventBuffer(onOrOff) {
    EventBuffer[onOrOff](NEW_HTML_EVENT_NAME, this.onNewHtml);
    EventBuffer[onOrOff](TOGGLE_CONTENT_EVENT_NAME, this.onToggleContentToggle);
    EventBuffer[onOrOff](UPDATE_DONE_EVENT_NAME, this.onUpdateDone);
    EventBuffer[onOrOff](UPDATE_START_EVENT_NAME, this.onUpdateStart);
  }

  onNewHtml(html) {
    this.didLoadHtml = true;

    const parentNode = this.innerTarget.parentNode; // eslint-disable-line prefer-destructuring

    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    parentNode.removeChild(this.innerTarget);
    parentNode.insertBefore(tempElement.firstChild, parentNode.lastChild);
  }

  onToggleContentToggle({ target = null, active = null } = {}) {
    if (!active || target !== TOGGLE_CONTENT_TARGET) {
      return;
    }

    if (!this.didLoadHtml) {
      this.loadHtml();
    }
  }

  onUpdateDone() {
    this.element.classList.remove(LOADING_CLASS_NAME);
  }

  onUpdateStart() {
    this.element.classList.add(LOADING_CLASS_NAME);
  }

  loadHtml() {
    this.didLoadHtml = true;

    fetchCart({ url: this.url });
  }

  get url() {
    return this.data.get('url');
  }
}

export {
  CartController,
  CartController as default,
};
