import { Controller } from 'stimulus';

import trackEvent from '../modules/track-event';

const TRACK_CALLBACK_TIMEOUT = 2500; // ms

class FormGlobalParentSignupController extends Controller {
  static targets = ['lookupButton'];

  connect() {
    this.trackEventType = this.data.get('submit-track-event');
    this.accountNumberEnteredManually = false;
    this.usedFetchAccounts = false;
    this.submitted = false;
    this.gotCallback = false;
  }

  onSubmit(event) {
    if (!this.submitted) {
      const trackEventProperties = {};

      event.preventDefault();

      // If lookup button is visible (could be invisible in A/B test)
      if (this.lookupButtonTarget.offsetParent !== null) {
        Object.assign(trackEventProperties, {
          usedFetchAccounts: this.usedFetchAccounts,
          accountNumberEnteredManually: this.accountNumberEnteredManually,
        });
      }

      trackEvent(this.trackEventType, trackEventProperties, () => {
        this.gotCallback = true;
        this.element.submit();
      });

      // It should be possible to submit form even if track serivce has problems.
      window.setTimeout(() => {
        if (!this.gotCallback) {
          this.element.submit();
        }
      }, TRACK_CALLBACK_TIMEOUT);

      this.submitted = true;
    }
  }

  beginLookup() {
    this.usedFetchAccounts = true;
  }

  changeAccountNumber() {
    this.accountNumberEnteredManually = true;
  }
}

export {
  FormGlobalParentSignupController,
  FormGlobalParentSignupController as default,
};
