import { Controller } from 'stimulus';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';

const CALLBACK_LOAD_EVENT = new CustomEvent('lazy-load-image-load');

class LazyLoadImagesController extends Controller {
  connect() {
    this.loader = new LazyLoad({
      elements_selector: '.js-lazy-load-image',
      callback_loaded: () => {
        window.dispatchEvent(CALLBACK_LOAD_EVENT);
      },
    });
  }
}

export {
  LazyLoadImagesController,
  LazyLoadImagesController as default,
};
