const Cookies = {
  set(key, value, days) {
    let expires = '';

    if (days) {
      const expiresDate = new Date();
      expiresDate.setTime(expiresDate.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${expiresDate.toGMTString()}`;
    }

    document.cookie = `${key}=${value}${expires}; path=/`;
  },

  get(key) {
    const keyPrefix = `${key}=`;

    const cookie = document.cookie.split(';').find(c => c.trim().indexOf(keyPrefix) === 0);

    if (cookie) {
      return cookie.substr(keyPrefix.length + 1);
    }

    return null;
  },

  unset(key) {
    Cookies.set(key, '', -1);
  },
};

export {
  Cookies,
  Cookies as default,
};
