import { Controller } from 'stimulus';

import { push as gtmPush } from '../modules/gtm';

export default class extends Controller {
  static targets = ['paymentMethod', 'cardType', 'radioAmount', 'customRadioAmount', 'customAmount', 'amount'];

  get cartItemsJson() {
    return JSON.parse(this.data.get('cart-items-json')) || [];
  }

  get selectedPaymentMethod() {
    if (!this.hasPaymentMethodTarget) return undefined;

    const paymentMethod = this.paymentMethodTargets.find(x => x.checked);

    return paymentMethod ? paymentMethod.value : undefined;
  }

  get selectedCardType() {
    let cardType;

    if (this.hasCardTypeTarget) {
      const cardTypeRadio = this.cardTypeTargets.find(x => x.checked);

      cardType = cardTypeRadio ? cardTypeRadio.value : undefined;
    }

    if (this.data.has('card-type')) {
      cardType = this.safeDataGet('card-type');
    }

    return cardType === 'mail' || cardType === 'printed'
      ? 'Printed card'
      : undefined;
  }

  get selectedAmount() {
    if (this.hasAmountTarget) {
      return this.amountTarget.value;
    }

    if (this.hasRadioAmountTarget) {
      const amountRadio = this.radioAmountTargets.find(x => x.checked);

      if (amountRadio.id === 'registration_total_sum_other') {
        return this.customRadioAmountTarget.value;
      }

      return amountRadio ? amountRadio.value : undefined;
    }

    if (this.hasCustomAmountTarget) {
      return this.customAmountTarget.value;
    }

    if (this.data.has('amount')) {
      return this.safeDataGet('amount');
    }

    return undefined;
  }

  get donationCertificatesCount() {
    const count = this.safeDataGet('donation-certificates-count') || 0;
    return Number(count);
  }

  checkoutVirtualGifts() {
    const event = {
      event: 'EECcheckout',
      donatorType: this.safeDataGet('donor-type'),
      typeOfCard: this.selectedCardType,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: this.safeDataGet('step'),
            option: this.selectedPaymentMethod,
          },
          products: this.cartItemsJson.map(item => this.toProduct(item)),
        },
      },
    };
    gtmPush(event);
  }

  checkout() {
    const donation = {
      name: this.safeDataGet('product-name'),
      id: this.safeDataGet('id'),
      price: this.selectedAmount,
      brand: 'Unicef',
      category: this.safeDataGet('product-category'),
      variant: undefined,
      quantity: 1,
    };

    const products = this.donationCertificatesCount > 0
      ? [donation, this.printedGiftCertificate(this.donationCertificatesCount)]
      : [donation];

    const event = {
      event: 'EECcheckout',
      donatorType: this.safeDataGet('donor-type'),
      typeOfCard: this.selectedCardType,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: this.safeDataGet('step'),
            option: this.selectedPaymentMethod,
          },
          products,
        },
      },
    };

    gtmPush(event);
  }

  checkoutOptionChange() {
    const event = {
      event: 'EECcheckoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step: this.safeDataGet('step'),
            option: this.selectedPaymentMethod,
          },
        },
      },
    };

    gtmPush(event);
  }

  checkoutAmountChange() {
    const event = {
      event: 'EECcheckoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step: this.safeDataGet('step'),
            option: this.selectedAmount,
          },
        },
      },
    };

    gtmPush(event);
  }

  toProduct(cartItem) {
    return {
      name: this.replaceNull(cartItem.name),
      id: this.replaceNull(cartItem.id),
      price: this.replaceNull(cartItem.price),
      brand: 'Unicef',
      category: 'Gifts',
      quantity: this.replaceNull(cartItem.quantity),
    };
  }

  replaceNull(thing) {
    return thing === null
      ? undefined
      : thing;
  }

  safeDataGet(attribute) {
    return this.replaceNull(this.data.get(attribute));
  }

  printedGiftCertificate(quantity) {
    return {
      name: 'Printed Gift Certificate',
      price: '20.0',
      brand: 'Unicef',
      quantity,
    };
  }
}
