import { Controller } from 'stimulus';

import { EventBuffer } from '../lib/event-buffer';

const EVENT_NAME_PREFIX = 'checkbox-toggle:';

class CheckboxToggleController extends Controller {
  checkedState = false;

  initialize() {
    this.onToggled = this.onToggled.bind(this);
  }

  connect() {
    if (this.isCheckbox) {
      this.checkedState = !!this.element.checked;

      if (this.eventName) {
        EventBuffer.on(this.eventName, this.onToggled);
      }
    }
  }

  disconnect() {
    if (this.eventName) {
      EventBuffer.off(this.eventName, this.onToggled);
    }
  }

  checkAction() {
    this.toggle(true);
  }

  uncheckAction() {
    this.toggle(false);
  }

  toggleAction() {
    this.toggle();
  }

  toggle(newCheckedState = !this.checkedState) {
    if (this.eventName) {
      EventBuffer.trigger(this.eventName, newCheckedState);
    } else {
      this.onToggled(newCheckedState);
    }
  }

  onToggled(checked) {
    if (this.isCheckbox) {
      this.element.checked = checked;
    }

    this.checkedState = checked;
  }


  get id() {
    return this.data.get('id') || undefined;
  }

  get eventName() {
    return this.id ? `${EVENT_NAME_PREFIX}${this.id}` : undefined;
  }

  get isCheckbox() {
    return typeof this.element.checked !== 'undefined';
  }
}

export {
  CheckboxToggleController,
  CheckboxToggleController as default,
};
