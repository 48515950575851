import { Controller } from 'stimulus';

const HIDDEN_CLASS_NAME = 'is-hidden';

class ShowIfUserAgentControllerMatchController extends Controller {
  connect() {
    const pattern = this.data.get('pattern');
    const re = new RegExp(pattern, 'i');
    const match = navigator.userAgent.match(re);

    if (match) {
      this.onMatch();
    }
  }

  onMatch() {
    this.element.classList.remove(HIDDEN_CLASS_NAME);
  }
}

export {
  ShowIfUserAgentControllerMatchController,
  ShowIfUserAgentControllerMatchController as default,
};
