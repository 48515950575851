import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    classname: String
  }

  static targets = ['element']

  toggle(e) {
    if (!this.hasElementTarget) return

    this.elementTargets.forEach(el => {
      if (e.target.checked) {
        el.classList.remove(this.classnameValue)
      } else {
        el.classList.add(this.classnameValue)
      }
    })
  }

  add() {
    if (!this.hasElementTarget) return

    this.elementTargets.forEach(e => {
      e.classList.add(this.classnameValue)
    })
  }

  remove() {
    if (!this.hasElementTarget) return

    this.elementTargets.forEach(e => {
      e.classList.remove(this.classnameValue)
    })
  }
}
