import { Controller } from 'stimulus';

class OptionFollowsTextInput extends Controller {
  static targets = ['option', 'text'];

  checkedState = false;

  initialize() {
    this.onOptionToggle = this.onOptionToggle.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  connect() {
    if (this.optionTarget) {
      this.optionTarget.addEventListener('change', this.onOptionToggle, true);
    }
    if (this.textTarget) {
      this.textTarget.addEventListener('keyup', this.onTextChange, true);
    }
    this.setValue();
  }

  disconnect() {
    if (this.optionTarget) {
      this.optionTarget.removeEventListener('change', this.onOptionToggle, true);
    }
    if (this.textTarget) {
      this.textTarget.removeEventListener('keyup', this.onTextChange, true);
    }
  }

  onOptionToggle() {
    this.toggle(this.optionTarget.checked);
  }

  onTextChange() {
    this.setValue();
    this.toggle(true);
  }

  setValue() {
    if (this.optionTarget && this.textTarget) {
      this.optionTarget.value = this.textTarget.value;
    }
  }

  toggle(newCheckedState = !this.checkedState) {
    this.onToggled(newCheckedState);
    this.setValue();
  }

  onToggled(checked) {
    if (this.optionTarget) {
      this.optionTarget.checked = checked;
    }

    if (checked && this.textTarget && window.document.activeElement !== this.textTarget) {
      this.textTarget.select();
    }
  }
}

export {
  OptionFollowsTextInput,
  OptionFollowsTextInput as default,
};
