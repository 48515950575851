import { Controller } from 'stimulus';

import trackEvent from '../modules/track-event';

const TRACK_CALLBACK_TIMEOUT = 2500; // ms

class LineItemsController extends Controller {
  static targets = ['removeForm'];

  connect() {
    this.submitted = false;
    this.gotCallback = false;
  }

  add() {
    trackEvent('Gift Card Offers - Used Card Quantity Stepper', {
      action: 'added',
    });
  }

  subtract() {
    trackEvent('Gift Card Offers - Used Card Quantity Stepper', {
      action: 'subtracted',
    });
  }

  remove(event) {
    if (!this.submitted) {
      event.preventDefault();

      trackEvent('Gift Card Offers - Used Card Quantity Stepper', {
        action: 'removed',
      }, () => {
        this.gotCallback = true;
        this.removeFormTarget.submit();
      });

      // It should be possible to submit form even if track serivce has problems.
      window.setTimeout(() => {
        if (!this.gotCallback) {
          this.removeFormTarget.submit();
        }
      }, TRACK_CALLBACK_TIMEOUT);

      this.submitted = true;
    }
  }
}

export default LineItemsController;
