/* eslint-disable import/prefer-default-export */

export const BANK_ID_AWAITING_SIGNATURE_STATE = 'awaiting_signature';
export const BANK_ID_CANCELLING_STATE = 'cancelling';
export const BANK_ID_CANCELLED_STATE = 'cancelled';
export const BANK_ID_COMPLETED_STATE = 'completed';
export const BANK_ID_ERROR_EVENT = 'bankid_error';
export const BANK_ID_FAILED_STATE = 'failed';
export const BANK_ID_PENDING_STATE = 'pending';

export const BANK_ID_ENTER_PENDING_STATE_ACTION = 'ENTER_PENDING_STATE';
export const BANK_ID_ERROR_ACTION = 'ERROR';
export const BANK_ID_HANDLE_RESPONSE_ACTION = 'HANDLE_RESPONSE';
