import { Controller } from 'stimulus';

import trackEvent from '../modules/track-event';
import Modal from '../modules/modal';

const ILLUSTRATION_LABEL = '.js-gift-card-preview-illustration-label';
const IS_SELECTED_STATE = 'is-selected';

class GiftCardPreviewController extends Controller {
  static targets = ['illustration', 'illustrationInput', 'illustrationList'];

  connect() {
    this.modal = null;
    this.offer = this.data.get('offer');
    this.illustrationLabels = null;
  }

  openIllustrationOptions(event) {
    event.preventDefault();

    this.openIllustrationOptionsModal();
  }

  openIllustrationOptionsModal() {
    if (!this.modal) {
      this.setupModal();
      this.illustrationLabels = Array.from(this.modal.element.querySelectorAll(ILLUSTRATION_LABEL));
    }

    this.modal.show();
  }

  setupModal() {
    this.modal = new Modal(this.illustrationListTarget.innerHTML, {
      template: {
        header: {
          heading: 'Välj motiv',
          close: 'Avbryt',
        },
      },
    });
  }

  changeIllustration(event) {
    this.illustrationTarget.src = event.target.getAttribute('data-illustration-source');

    this.illustrationLabels.forEach((illustrationLabel) => {
      illustrationLabel.classList.remove(IS_SELECTED_STATE);
    });

    this.illustrationLabels
      .find(illustrationLabel => illustrationLabel.getAttribute('for') === event.target.id)
      .classList.add(IS_SELECTED_STATE);

    this.modal.hide();

    trackEvent('Gift Card Offers - Changed Motif', {
      offer: this.offer,
    });
  }
}

export {
  GiftCardPreviewController,
  GiftCardPreviewController as default,
};
