import { Controller } from 'stimulus';

const GOOGLE_MAPS_LOAD_EVENT = 'google-maps-load';
const GOOGLE_MAPS_API_KEY = 'AIzaSyDcf372leWSGdnmWWTvzcERBDEoBiwyWf4';
const GOOGLE_MAPS_CALLBACK_NAME = 'dispatchGoogleMapsLoadEvent';
const MAP_GLOBAL_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};
const MAP_INSTANCE_DEFAULT_OPTIONS = {
  center: [40, 0],
  zoom: 2,
};
const MAP_STYLE = [
  {
    featureType: 'landscape',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'landscape.natural',
    stylers: [{ visibility: 'on' }, { color: '#d1eafa' }],
  }, {
    featureType: 'landscape.man_made',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'administrative',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'road',
    stylers: [{ visibility: 'off' }],
  }, {
    featureType: 'water',
    stylers: [{ color: '#ffffff' }],
  }, {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];
const MAP_STYLE_COUNTRIES = {
  fillColor: '#00aeef',
  fillOpacity: 1,
  strokeWeight: 0,
};
const MAP_STYLE_HIGHLIGHTED_COUNTRY = {
  fillColor: '#003860',
  fillOpacity: 1,
  strokeWeight: 0,
};
const HIGHLIGHTED_CLASS = 'is-highlighted';
let googleMapsLoaded = false;

window[GOOGLE_MAPS_CALLBACK_NAME] = () => {
  const mapCallbackEvent = new CustomEvent(GOOGLE_MAPS_LOAD_EVENT);

  window.dispatchEvent(mapCallbackEvent);
};

class CountriesMapController extends Controller {
  static targets = ['map', 'countries', 'country'];

  initialize() {
    if (!googleMapsLoaded) {
      this.loadGoogleMaps();

      googleMapsLoaded = true;
    }
  }

  connect() {
    const options = this.element.getAttribute('data-options') || '{}';
    const styles = this.element.getAttribute('data-styles');

    this.map = null;
    this.countries = JSON.parse(this.element.getAttribute('data-countries'));
    this.options = Object.assign({}, MAP_INSTANCE_DEFAULT_OPTIONS, JSON.parse(options));
    this.styles = styles ? JSON.parse(styles) : null;
    this.onGoogleMapsLoad = this.onGoogleMapsLoad.bind(this);
    this.highlightedCountry = null;

    window.addEventListener(GOOGLE_MAPS_LOAD_EVENT, this.onGoogleMapsLoad, false);
  }

  loadGoogleMaps() {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3&callback=${GOOGLE_MAPS_CALLBACK_NAME}`;

    document.body.appendChild(script);
  }

  onGoogleMapsLoad() {
    const mapStyle = JSON.parse(JSON.stringify(MAP_STYLE)); // Deep clone

    if (this.styles && this.styles.landscape) {
      mapStyle.find(type => type.featureType === 'landscape.natural').stylers[1].color = this.styles.landscape;
    }

    if (this.styles && this.styles.water) {
      mapStyle.find(type => type.featureType === 'water' && !type.elementType).stylers[0].color = this.styles.water;
    }

    this.options.center = new window.google.maps.LatLng(this.options.center[0], this.options.center[1]);
    this.map = new window.google.maps.Map(this.mapTarget, Object.assign({}, MAP_GLOBAL_OPTIONS, this.options));
    this.map.mapTypes.set('simple', new window.google.maps.StyledMapType(mapStyle, { map: this.map }));
    this.map.setMapTypeId('simple');

    this.setupMapData();

    if (this.hasCountriesTarget) {
      this.setupMapEventListeners();
      this.setupCountryNameListeners();
    }
  }

  setupMapData() {
    const style = Object.assign({}, MAP_STYLE_COUNTRIES);

    if (this.styles) {
      style.fillColor = this.styles.country;
    }

    this.map.data.addGeoJson(this.countries);
    this.map.data.setStyle(() => (style));
  }

  setupMapEventListeners() {
    const overrideStyle = Object.assign({}, MAP_STYLE_HIGHLIGHTED_COUNTRY);

    if (this.styles) {
      overrideStyle.fillColor = this.styles.highlighted;
    }

    this.map.data.addListener('mouseover', (event) => {
      const id = event.feature.getId();

      this.map.data.overrideStyle(event.feature, overrideStyle);

      this.highlightCountryName(id);
    });

    this.map.data.addListener('mouseout', (event) => {
      this.map.data.revertStyle(event.feature);

      this.removeCountryNameHighlights();
    });
  }

  highlightCountryName(id) {
    const country = this.countryTargets.find(el => parseInt(el.getAttribute('data-id'), 10) === id);

    country.classList.add(HIGHLIGHTED_CLASS);
  }

  removeCountryNameHighlights() {
    this.countryTargets.forEach((el) => {
      el.classList.remove(HIGHLIGHTED_CLASS);
    });
  }

  setupCountryNameListeners() {
    const overrideStyle = Object.assign({}, MAP_STYLE_HIGHLIGHTED_COUNTRY);

    if (this.styles) {
      overrideStyle.fillColor = this.styles.highlighted;
    }

    this.countriesTarget.addEventListener('mouseover', (event) => {
      const id = event.target.getAttribute('data-id');

      if (id) {
        event.target.classList.add(HIGHLIGHTED_CLASS);

        const highlightedFeature = this.map.data.getFeatureById(id);

        this.map.data.overrideStyle(highlightedFeature, overrideStyle);
      }
    });

    this.countriesTarget.addEventListener('mouseout', (event) => {
      const id = event.target.getAttribute('data-id');

      if (id) {
        event.target.classList.remove(HIGHLIGHTED_CLASS);

        const feature = this.map.data.getFeatureById(id);

        this.map.data.revertStyle(feature);
      }
    });
  }
}

export {
  CountriesMapController,
  CountriesMapController as default,
};
