/* eslint-disable import/prefer-default-export */

export const ANALYTICS_CHANGE_EVENT_NAME = 'cart-item:change';
export const CART_SIZE_HEADER_NAME = 'X-Unicef-Cart-Size';
export const CHANGE_DEBOUNCE_DELAY = 500;
export const LOADING_CLASS_NAME = 'is-loading';
export const NEW_HTML_EVENT_NAME = 'cart:html:new';
export const SIZE_CHANGE_EVENT_NAME = 'cart:size:change';
export const TOGGLE_CONTENT_TARGET = 'cart';
export const UPDATE_DONE_EVENT_NAME = 'cart:update:done';
export const UPDATE_START_EVENT_NAME = 'cart:update:start';
