/* eslint-disable import/prefer-default-export */

export const ACTIVE_CLASS_NAME = 'is-open';
export const EVENT_NAME = 'toggle-content';
export const HIDE_MAIN_CONTENT_BEHAVIOR = 'hide-main-content';
export const DISABLE_FORM_ELEMENTS_BEHAVIOR = 'disable-form-elements';
export const MAIN_CONTENT_SELECTOR = '.js-main-content';
export const PARENT_ACTIVE_CLASS_NAME = 'has-open';
export const PARENT_TARGET_ACTIVE_CLASS_NAME_BASE = 'has-open-';
export const PARENT_TARGET_ACTIVE_CLASS_NAME_REGEXP = new RegExp(`^${PARENT_TARGET_ACTIVE_CLASS_NAME_BASE}.*$`, 'i');
export const FOCUS_SELECTOR = '.js-focus';
export const TOGGLE_TARGET_ACTION = 'toggleTarget';
