import { Controller } from 'stimulus';

import { Cookies } from '../lib/cookies';
import { EventBuffer } from '../lib/event-buffer';

const HIDDEN_CLASS_NAME = 'is-hidden';
const EVENT_NAME_PREFIX = 'permanently-dismissed:';

class PermanentlyDismissableController extends Controller {
  initialize() {
    this.onDismissed = this.onDismissed.bind(this);
  }

  connect() {
    const isSet = Cookies.get(this.key);

    if (!isSet) {
      this.element.classList.remove(HIDDEN_CLASS_NAME);
    }

    if (this.eventName) {
      EventBuffer.on(this.eventName, this.onDismissed);
    }
  }

  disconnect() {
    if (this.eventName) {
      EventBuffer.off(this.eventName, this.onDismissed);
    }
  }

  dismiss() {
    if (this.key) {
      Cookies.set(this.key, true, 999);
    }

    if (this.eventName) {
      EventBuffer.trigger(this.eventName);
    } else {
      this.onDismissed();
    }
  }

  onDismissed() {
    this.element.classList.add(HIDDEN_CLASS_NAME);
  }

  get id() {
    return this.data.get('id') || undefined;
  }

  get eventName() {
    return this.id ? `${EVENT_NAME_PREFIX}${this.id}` : undefined;
  }

  get key() {
    /* eslint-disable no-underscore-dangle */

    let key = this._key;

    if (!key && this.id) {
      key = `${this.id || Date.now()}_permanently-dismissed`;
      this._key = key;
    }

    return key;
  }
}

export {
  PermanentlyDismissableController,
  PermanentlyDismissableController as default,
};
