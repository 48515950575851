/*
 * Track Controller
 *
 * Track click on elements which has the `data-track` attribute to `Unicef.Track`
 * Has the option to follow the link with `window.location` or not by setting the option
 * in a data attribute: `data-track-follow-link="false"`. Defaults to `true`.
 *
 */

import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

import Tracking from '../modules/tracking';

import {
  TRACK_RESIZE_DEBOUNCE_DELAY,
  TRACK_SCROLL_DEBOUNCE_DELAY,
  TRACK_SCROLL_PERCENTAGES,
} from '../constants/track';

const FORM_TAG_NAME = 'form';
const TRACK_AS_DATA_ATTRIBUTE = 'data-track-as';
const TRACK_AS_DATA_SELECTOR = `[${TRACK_AS_DATA_ATTRIBUTE}]`;

const { documentElement, body } = window.document;

// Return element if it has the data attribute "track"
function isTrackElement(el, { matchForm = false } = {}) {
  if (!el) {
    return false;
  }

  if (!matchForm && el.tagName.toLowerCase() === FORM_TAG_NAME) {
    return false;
  }

  return el.dataset && el.dataset.track && el;
}

function isExternalLink(el) {
  if (!el) {
    return false;
  }

  // Match when target starts with ”http[s]://” AND doesn't include the current host
  const regexp = `^http[s]?://((?!${document.location.host.replace('.', '\\.')})).*`;
  const href = el.getAttribute('href');

  return href && href.match(regexp) && el;
}

function triggerEventPromise(action, payload) {
  return new Promise(resolve => Tracking.triggerEvent(action, {
    ...payload,
    event_callback: resolve,
  }));
}

class TrackController extends Controller {
  scrollPercentagesTriggered = [];

  initialize() {
    this.handleScroll = !!this.data.get('scroll');
    this.ignoreScrollOnSelector = this.data.get('scroll-ignore-on-selector');

    this.onResize = debounce(this.onResize.bind(this), TRACK_RESIZE_DEBOUNCE_DELAY);
    this.onScroll = debounce(this.onScroll.bind(this), TRACK_SCROLL_DEBOUNCE_DELAY);
  }

  connect() {
    if (this.handleScroll) {
      window.addEventListener('resize', this.onResize, false);
      window.addEventListener('scroll', this.onScroll, false);

      this.onResize();
    }
  }

  disconnect() {
    if (this.handleScroll) {
      window.removeEventListener('resize', this.onResize, false);
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }

  click(e) {
    const ancestor = e.target.closest('[data-track], a');
    const trackEl = isTrackElement(e.target) || isTrackElement(ancestor);

    if (!trackEl) {
      const externalLinkEl = isExternalLink(e.target) || isExternalLink(ancestor);

      if (externalLinkEl) {
        e.preventDefault();
        this.trackExternalLink(externalLinkEl);
      }

      return;
    }

    e.preventDefault();

    this.trackTrackingLink(trackEl);
  }

  submit(e) {
    const form = e.target.closest(FORM_TAG_NAME);
    const trackEl = isTrackElement(form, { matchForm: true });

    if (!trackEl) {
      return;
    }

    e.preventDefault();

    this.trackForm(trackEl);
  }

  trackExternalLink(el) {
    Tracking.triggerEvent('external_link', {
      event_category: 'engagement',
      event_label: el.getAttribute('href'),
      event_callback() {
        window.location = el.getAttribute('href');
      },
    });
  }

  // Track clickable element with `track` data attributes
  trackTrackingLink(el) {
    const {
      trackFollowLink, trackPath, trackAction, trackCategory, trackLabel,
    } = el.dataset;
    const href = el.getAttribute('href');
    let followLink = JSON.parse(trackFollowLink || true); // default value is true

    const processedTrackPath = trackPath === 'auto' ? null : trackPath;

    Tracking.triggerEvent(trackAction, {
      page_path: typeof processedTrackPath !== 'undefined' ? processedTrackPath : href,
      event_category: trackCategory,
      event_label: trackLabel,
      event_callback() {
        if (followLink && href) {
          // This is to prevent two execution of this, in the case when there's a link to a file,
          // which will not leave the page. This prevent it to run twice.
          // Check out: `Unicef.Track.triggerEvent` function
          followLink = false;

          window.location = href;
        }
      },
    });
  }

  // Track form element with `track` data attributes
  trackForm(el) {
    const {
      trackPath,
      trackAction,
      trackCategory,
    } = el.dataset;
    let {
      trackLabel,
    } = el.dataset;

    const formData = new FormData(el);
    const inputsToTrack = Array.from(el.querySelectorAll(TRACK_AS_DATA_SELECTOR));

    try {
      trackLabel = JSON.parse(trackLabel);
    } catch (e) {} // eslint-disable-line no-empty

    if ((typeof trackLabel === 'object') && (typeof trackLabel.input_name === 'string')) {
      trackLabel = formData.get(trackLabel.input_name);
    }

    const extraData = inputsToTrack.reduce((result, input) => ({
      ...result,
      [input.getAttribute(TRACK_AS_DATA_ATTRIBUTE)]: formData.get(input.getAttribute('name')),
    }), {});

    const allEvents = Object.entries(extraData).reduce((result, [action, label]) => ([
      ...result,
      [
        action,
        {
          page_path: trackPath,
          event_category: trackCategory,
          event_label: label,
        },
      ],
    ]), [trackAction ? [
      trackAction,
      {
        page_path: trackPath,
        event_category: trackCategory,
        event_label: trackLabel,
      },
    ] : null]).filter(a => !!a);

    let eventChain = Promise.resolve();

    allEvents.forEach(([action, payload]) => {
      eventChain = eventChain.then(() => triggerEventPromise(action, payload));
    });

    eventChain.then(() => {
      el.submit();
    });
  }

  onResize() {
    this.viewportHeight = documentElement.clientHeight;
    this.contentHeight = body.clientHeight; // eslint-disable-line prefer-destructuring

    this.onScroll();
  }

  onScroll() {
    const {
      handleScroll,
      ignoreScrollOnSelector,
      contentHeight,
      viewportHeight,
    } = this;

    if (!handleScroll || (ignoreScrollOnSelector && window.document.querySelectorAll(ignoreScrollOnSelector).length)) {
      return;
    }

    const scrollTop = documentElement.scrollTop; // eslint-disable-line prefer-destructuring
    const scrollPercentage = Math.round((scrollTop / (contentHeight - viewportHeight)) * 100);

    const percentagesToTrigger = TRACK_SCROLL_PERCENTAGES.filter(p => (
      p <= scrollPercentage && !this.scrollPercentagesTriggered.includes(p)
    ));

    percentagesToTrigger.forEach((p) => {
      this.scrollPercentagesTriggered.push(p);

      Tracking.triggerEvent('page_scroll', {
        event_label: `${p}%`,
        event_category: 'engagement',
      });
    });
  }
}

export default TrackController;
