import { html } from 'common-tags';

export function blogFeedPostTemplate(post) {
  return html`
    <div class="Grid-item Grid-item--12-of-24 Grid-item-ml--6-of-24 u-spacingBottomDecaSteep">
      <a class="ArticleTeaser ArticleTeaser--linked ArticleTeaser--white ArticleTeaser--has-image" href="${post.url}">
        ${post.image ? (html`
          <div class="ArticleTeaser-media">
            <img src="${post.image}" alt="${post.image_alt}">
            ${post.video ? (html`
              <div class="PlayOverlay PlayOverlay--thumb">
                <div class="PlayOverlay-button">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 65" class="PlayOverlay-buttonInner"><path d="M.963.073l70 32.5-70 32.5z"></path></svg>
                </div>
              </div>
            `) : ''}
          </div>
        `) : ''}
        <div class="ArticleTeaser-body">
          <div class="ArticleTeaser-kicker"><time>${post.formattedDate}</time> i bloggen</div>
          <h3 class="ArticleTeaser-heading u-spacingBottomNanoSteep">${post.title}</h2>
          <div class="ArticleTeaser-description">
            <p>${post.shortExcerpt}</p>
          </div>
        </div>
      </a>
    </div>
  `;
}
