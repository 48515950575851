import { Application } from 'stimulus';

function UnicefApp(...args) {
  return Application.call(this, ...args);
}

UnicefApp.prototype = Object.create(Application.prototype);

UnicefApp.prototype.constructor = UnicefApp;

/* Swallow "Error parsing descriptor string" errors,
 * since that means that we are seeing "old" markup inside a controller
 * element. It's gonna happen.
 */
UnicefApp.prototype.handleError = function handleError(error, message, detail) {
  if (message && message.indexOf('Error parsing descriptor string') !== -1) {
    console.warn('%s\n%s', 'Non-Stimulus markup inside controller', error && error.message ? error.message : message); /* eslint-disable-line no-console */
  } else {
    Application.prototype.handleError.call(this, error, message, detail);
  }
};

UnicefApp.start = function start(element, schema) {
  const application = new UnicefApp(element, schema);
  application.start();
  return application;
};

export {
  UnicefApp,
  UnicefApp as default,
};
