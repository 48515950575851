const FATAL_LEVEL = 'fatal';
const ERROR_LEVEL = 'error';
const WARNING_LEVEL = 'warning';
const INFO_LEVEL = 'info';
const DEBUG_LEVEL = 'debug';

const LOG_METHOD = 'log';
const ERROR_METHOD = 'error';
const WARN_METHOD = 'warn';

const LEVELS = [
  FATAL_LEVEL,
  ERROR_LEVEL,
  WARNING_LEVEL,
  INFO_LEVEL,
  DEBUG_LEVEL,
];

const Logger = {
  sendMessage(message, tags = {}, level = DEBUG_LEVEL, extra = {}) {
    const consoleArgs = [message];
    let consoleMethod = LOG_METHOD;
    if ('Sentry' in window) {
      window.Sentry.withScope((scope) => {
        scope.setTags(tags);
        if (extra) {
          Object.keys(extra).forEach((key) => {
            scope.setExtra(key, extra[key]);
          });
        }

        if (message instanceof Error) {
          window.Sentry.captureException(message);
        } else {
          window.Sentry.captureMessage(message, level);
        }
      });
    } else {
      switch (level) {
        case FATAL_LEVEL:
        case ERROR_LEVEL:
          consoleMethod = ERROR_METHOD;
          break;
        case WARNING_LEVEL:
          consoleMethod = WARN_METHOD;
          break;
        default:
      }

      if (Object.keys(tags).length) {
        consoleArgs.push(tags);
      }

      // eslint-disable-next-line no-console
      console[consoleMethod].apply(this, consoleArgs);
    }
  },
  logStimulus(error, message, detail) {
    if ('Sentry' in window) {
      window.Sentry.captureException(error, {
        level: ERROR_LEVEL,
        message,
        ...detail,
      });
    }
  },
};

LEVELS.forEach((level) => {
  // eslint-disable-next-line func-names
  Logger[level] = function (message, tags = {}, detail = {}) {
    this.sendMessage(message, tags, level, detail);
  };
});

export default Logger;
