import { Controller } from 'stimulus';

class ReplaceWithXhrResponseController extends Controller {
  initialize() {
    this.onAjaxSuccess = this.onAjaxSuccess.bind(this);
    this.onAjaxError = this.onAjaxError.bind(this);
  }

  connect() {
    this.element.addEventListener('ajax:success', this.onAjaxSuccess);
    this.element.addEventListener('ajax:error', this.onAjaxError);
  }

  disconnect() {
    this.element.removeEventListener('ajax:success', this.onAjaxSuccess);
    this.element.removeEventListener('ajax:error', this.onAjaxError);
  }

  onAjaxSuccess(event) {
    const xhr = event.detail[2];

    this.element.innerHTML = xhr.responseText;
  }

  onAjaxError(event) {
    const xhr = event.detail[2];

    throw new Error(xhr.responseText);
  }
}

export {
  ReplaceWithXhrResponseController,
  ReplaceWithXhrResponseController as default,
};
