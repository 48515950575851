import { Controller } from 'stimulus';

import Modal from '../modules/modal';

class ShowElementInModal extends Controller {
  connect() {
    const content = this.element.innerHTML;
    const options = {
      template: {
        header: {
          heading: this.data.get('heading'),
        },
      },
      onHide: this.onHide.bind(this),
    };
    const modal = new Modal(content, options);

    modal.show();
  }

  removeConfirmationFromURL() {
    let locationSearch = window.location.search;
    let newPath;

    if (locationSearch) {
      locationSearch = locationSearch.replace(/confirmation=1&?/, '').replace(/\?$|&$/, '');
      newPath = window.location.pathname + locationSearch;

      window.history.replaceState({}, document.title, newPath);
    }
  }

  onHide() {
    this.removeConfirmationFromURL();
  }
}

export {
  ShowElementInModal,
  ShowElementInModal as default,
};
