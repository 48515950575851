import { Controller } from 'stimulus';

const IS_INVALID_CLASS_NAME = 'is-invalid';

class ValidateFormController extends Controller {
  connect() {
    const inputs = Array.from(this.element.querySelectorAll('input, select, textarea'));

    inputs.forEach((input) => {
      // The invalid event fires just before submit
      input.addEventListener('invalid', () => {
        input.classList.add(IS_INVALID_CLASS_NAME);
      }, false);

      input.addEventListener('blur', () => {
        const valid = input.checkValidity();

        if (valid) {
          input.classList.remove(IS_INVALID_CLASS_NAME);
        }
      });
    });
  }
}

export {
  ValidateFormController,
  ValidateFormController as default,
};
